import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import {FormGrid} from '@internal/components';

import SurveySettingsAPI from '@internal/apis/SurveySettingsAPI';
import SurveyReportsAPI from '@internal/apis/SurveyReportsAPI';

const FormConfiguration: React.FC<BasePageProps> = ({
  navigatePage,
  urlParams,
}) => {
  const [loading, setLoading] = React.useState(true);
  const settingsData = React.useRef({} as any);
  const settingsBase = React.useRef([] as any);
  const formConfig = React.useRef<any>([
    {
      name: 'isSurveyCompleted',
      label: 'Survey Completed',
      fieldType: 'select',
      isRequired: true,
      options: {
        data: [
          {id: '2', value: 'No'},
          {id: '1', value: 'Yes'},
        ],
        dictionary: {id: 'id', value: 'value'},
      },
      value: '1',
      validations: {
        required: true,
      },
    },
    {
      name: 'defaultReport',
      label: 'Survey Report',
      fieldType: 'select',
      isRequired: false,
      options: {
        data: [],
        dictionary: {id: 'id', value: 'year'},
      },
    },
    {
      name: 'pageTitle',
      label: 'Survey Page Title',
      fieldType: 'input',
      isRequired: true,
      value: 'Welcome back!',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Title can't be blank.",
        },
      },
    },
    {
      name: 'surveyReportURL',
      label: 'Survey Report URL',
      fieldType: 'input',
      isRequired: true,
      value: '/reports',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "URL can't be blank.",
        },
      },
    },
    {
      name: 'surveyPageURL',
      label: 'Survey Page URL',
      fieldType: 'input',
      isRequired: true,
      value: '/',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "URL can't be blank.",
        },
      },
    },
    {
      name: 'surveyIntro',
      label: 'Survey Introduction Paragraph',
      fieldType: 'editor',
      isRequired: true,
      value: 'This is Survey',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Survey Introduction Paragraph can't be blank.",
        },
      },
    },
    {
      name: 'surveyMaintenance',
      label: 'Maintenance Text',
      fieldType: 'editor',
      isRequired: true,
      value:
        '<p class="ql-align-justify">The Startup Muster 2023 survey has now closed. Our report will be released on October 30th 2023, and we can’t wait to share how Australia’s startup ecosystem has changed since our 2018 report.</p><p><br></p><p class="ql-align-justify">Thank you to <strong>Atlassian</strong>, <strong>NSW Government</strong>, <strong>Microsoft</strong>,<strong>Antler</strong>,<strong>Parramatta City Council</strong> and <strong>The Gild Group</strong> for making the 2023 survey possible.</p><p><br></p><p class="ql-align-justify">To be notified when the report is published, please enter your email address below:</p>',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Maintenance Text can't be blank.",
        },
      },
    },
    {
      name: 'enableEmailReportSubscriber',
      label: 'Email Report Subscriber',
      fieldType: 'select',
      isRequired: true,
      options: {
        data: [
          {id: '2', value: 'No'},
          {id: '1', value: 'Yes'},
        ],
        dictionary: {id: 'id', value: 'value'},
      },
      value: '1',
      validations: {
        required: true,
      },
    },
    {
      name: 'mailReportSubscriberThankYourMessage',
      label: 'Maintenance Subscriber Thank You Message',
      fieldType: 'editor',
      isRequired: true,
      value: 'Thankyou Survey',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Thankyou Text can't be blank.",
        },
      },
    },
  ]);

  React.useEffect(() => {
    void reloadFunc().then();
  }, []);
  const reloadFunc = async (): Promise<void> => {
    setLoading(true);

    settingsBase.current = await SurveySettingsAPI.getList(navigatePage, false);
    settingsBase.current.map((setting: any) => {
      settingsData.current[setting.name] = setting.setting;
      return setting;
    });
    const data = await SurveyReportsAPI.getList(navigatePage, false);

    if (data && data.length > 0) {
      formConfig.current[1].isRequired = true;
      formConfig.current[1].options.data = data;
      formConfig.current[1].validations = {
        required: true,
        customMessages: {
          required: 'Default Report is required.',
        },
      };
    }

    formConfig.current.map((field: any) => {
      field.value = settingsData.current[field.name] ?? field.value;
      return field;
    });
    setLoading(false);
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    const dataValues: any = Object.entries(data);
    dataValues.forEach(async ([key, value]: any, index: number) => {
      const input = {
        name: key,
        setting: value,
      };
      if (settingsData.current?.[key]) {
        await SurveySettingsAPI.update(
          navigatePage,
          settingsBase.current.find((configuration: any) => {
            return configuration.name === key;
          }),
          input,
        );
      } else {
        await SurveySettingsAPI.save(navigatePage, input);
      }
      if (index + 1 === dataValues.length) {
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <FormGrid
        fields={formConfig.current ?? []}
        onSubmit={handleFormSubmit}
        submitButtonText={'Save'}
        doShowCardHeader
        reloadFunc={reloadFunc}
        title={'Survey Settings'}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default FormConfiguration;
