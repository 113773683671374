import {styled} from 'styled-components';
import Box from '@mui/material/Box';

const ReportPreviousDivParent = styled(Box)`
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0);
  border-radius: 3px;
  flex-shrink: 1;
  padding: 1.25rem;
  margin: 0 0.625rem;
  text-align: center;
  max-width: 15.625rem;
  font-weight: 600;
`;

const ReportPreviousImage = styled.img`
  display: block;
  border: 1px solid #bbc5ca;
  margin: 0;
  max-width: 100%;
`;

const ReportPreviousSpan = styled.span`
  white-space: nowrap;
`;

const ReportDefaultDivParent = styled(Box)`
  cursor: pointer;
  border: solid 1px #bbc5ca;
  max-width: 25rem;
  border-radius: 3px;
  flex-shrink: 1;
  padding: 1.25rem;
  margin: 0 0;
  text-align: center;
  font-weight: 600;
`;

const ReportDefaultImage = styled.img`
  display: block;
  border: 1px solid #bbc5ca;
  margin: 0;
  max-width: 100%;
`;

const ReportDefaultP = styled.p`
  max-width: none !important;
  margin: 0 0 1.25rem;
`;

export {
  ReportPreviousDivParent,
  ReportPreviousImage,
  ReportPreviousSpan,
  ReportDefaultDivParent,
  ReportDefaultImage,
  ReportDefaultP,
};
