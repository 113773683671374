import AsyncStorage from './AsyncStorage';
import SurveyDownloadReportEmailTypes from "@internal/types/SurveyDownloadReportEmailTypes";

const CacheKey = 'SurveyDownloadReportEmailCache';

const SurveyDownloadReportEmailCache = {
  setItem: async (value: SurveyDownloadReportEmailTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<SurveyDownloadReportEmailTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as SurveyDownloadReportEmailTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default SurveyDownloadReportEmailCache;
