import React from 'react';
import {DataGrid} from '@internal/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CMSHeadScriptsAPI from '@internal/apis/CMSHeadScriptsAPI';
import type CMSHeadScriptsTypes from '@internal/types/CMSHeadScriptsTypes';
import type BasePageProps from '@internal/pages/BasePageProps';

const Scripts: React.FC<BasePageProps> = ({navigatePage}) => {
  const [record, setRecord] = React.useState<CMSHeadScriptsTypes[]>([]);
  const loadRecords = async (
    refreshCache: boolean,
    page: number,
  ): Promise<number> => {
    setRecord(
      await CMSHeadScriptsAPI.getList(navigatePage, refreshCache, page),
    );
    return await CMSHeadScriptsAPI.getPageNumbers();
  };

  return (
    <DataGrid
      sm={4}
      itemProps={[
        {type: 'data', key: 'name', text: 'Script Name'},
        {type: 'data', key: 'script', text: 'Script'},
      ]}
      items={record}
      itemActions={[
        {
          icon: <EditIcon color="primary" />,
          handler: async (id: any) => {
            navigatePage('/members/cms/scripts/update/' + id);
          },
        },
        {
          icon: <DeleteIcon color="primary" />,
          handler: async (id: any) => {
            if (await CMSHeadScriptsAPI.delete(navigatePage, id)) {
              await loadRecords(false, 1);
            }
          },
        },
      ]}
      addInfo={{
        text: 'Script',
        handler: () => {
          navigatePage('add');
        },
      }}
      actionID={'id'}
      loadRecords={loadRecords}
      doShowCardHeader
      doShowTableHeader
    />
  );
};

export default Scripts;
