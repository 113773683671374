import InternalVerions from './InternalVerions';

const SystemInternalReadonlyVariablesRoutes = {
    systemInternalVerions: {
        Component: InternalVerions,
        type: 'private',
        route: '/members/system/internal-versions',
        title: 'System Readonly Variables',
        breadcrumb: [{title: 'Home', route: '/'}, {title: 'System'}],
    },
};

export default SystemInternalReadonlyVariablesRoutes;
