import {styled} from 'styled-components';
import Box from '@mui/material/Box';

const Header = styled(Box)`
  background-color: #367fbb;
  padding: 2.5rem 2.5rem 0;
  @media (max-width: 720px) {
    padding: 1.25rem 1.25rem 0;
  }
`;

export default Header;
