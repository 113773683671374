import styled from 'styled-components';

const LoginLeftBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-right: 1px solid black;
  min-height: 511px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginRightBox = styled.div`
  flex: 1;
  width: 100%;
  max-width: 470px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  padding: 2rem;
  min-height: 511px;

  @media (max-width: 768px) {
    flex: 1;
    max-width: 100%;
  }
`;

const LoginDualBox = styled.div`
  display: flex;
  flex: 1;
`;

export {LoginDualBox, LoginRightBox, LoginLeftBox};
