import {styled} from 'styled-components';
import Typography from '@mui/material/Typography';

const PageTitleTypography = styled(Typography)`
  font-size: 42px !important;
  color: #fff !important;
  text-shadow: 0 1px 2px rgba(68, 84, 106, 0.6) !important;
  font-weight: 700 !important;
  text-align: center !important;
  line-height: 1.2 !important;
  margin: 0 0 2.5rem !important;
`;
export default PageTitleTypography;
