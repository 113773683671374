import React, {useState} from 'react';
import ValidateField from './ValidationUtils';
import type FieldValidators from './FieldValidators';

export const useFieldValidation = (
  fieldValidators: any,
  initialValue?: any,
): {
  setValue: (value: ((prevState: string) => string) | string) => void;
  value: string;
  errors: Array<string | null>;
  validate: () => boolean;
  validateByValue: (argValue: string) => void;
} => {
  const [value, setValue] = useState<string>('');
  const [errors, setErrors] = useState<Array<string | null>>([]);

  React.useEffect(() => {
    setValue(initialValue ?? '');
  }, []);
  const validate = () => {
    const validationErrors = ValidateField(
      fieldValidators as FieldValidators,
      value,
    );

    setErrors(validationErrors);

    return validationErrors.length > 0;
  };

  const validateByValue = (argValue: string) => {
    const validationErrors = ValidateField(
      fieldValidators as FieldValidators,
      argValue,
    );
    setErrors(validationErrors);
  };
  return {
    value,
    errors,
    validate,
    setValue,
    validateByValue,
  };
};
