import styled from 'styled-components';
import {Card} from '@mui/material';

const StyledGridCard = styled(Card)`
  background-color: rgb(255, 255, 255);
  color: rgba(47, 43, 61, 0.78);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  border-radius: 12px; // Increased radius

  // Deeper shadow for 3D effect
  box-shadow:
    0px 3px 10px rgba(0, 0, 0, 0.15),
    0px 3px 20px rgba(0, 0, 0, 0.1);

  background-image: none;
  border: 1px solid rgba(47, 43, 61, 0.16);
`;

export default StyledGridCard;
