import ProtectedRoutes from './protected';
import MemberRoutes from './members';
import PublicRoutes from './public';

const PageRoutes = {
  ...ProtectedRoutes,
  ...MemberRoutes,
  ...PublicRoutes,
};

export default PageRoutes;
