import {styled} from 'styled-components';
import Typography from '@mui/material/Typography';

const MenuLink = styled(Typography)`
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  cursor: pointer;
  &:hover {
    color: #ffd770;
    text-decoration: none;
    border-bottom: 0.1875rem solid #ffd770;
    padding-bottom: 0;
  }
`;
export default MenuLink;
