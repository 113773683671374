import React, {useState} from 'react';
import type CurvedEditorElementProps from './CurvedEditorElementProps';
import {StyledReactQuill, StyledEditorTextArea} from '@internal/styles';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const CurvedEditorElement: React.FC<CurvedEditorElementProps> = ({
  label,
  errorInfo,
  isRequired,
  onChangeInput,
  onChange,
  name,
  initialValue,
  ...props
}) => {
  const [mode, setMode] = useState<'editor' | 'code'>('editor');
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const isError = errorInfo?.hasError;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{header: 1}, {header: 2}],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{script: 'sub'}, {script: 'super'}],
      [{indent: '-1'}, {indent: '+1'}],
      [{direction: 'rtl'}],
      [{size: ['small', false, 'large', 'huge']}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      [{font: []}],
      [{align: []}],
      ['clean'],
      ['link', 'image', 'video'],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'header',
    'list',
    'script',
    'indent',
    'direction',
    'size',
    'color',
    'background',
    'font',
    'align',
    'link',
    'image',
    'video',
  ];

  const handleInputChange = (e: any) => {
    let val = e;
    if (e.target) {
      val = e.target.value;
    }
    setValue(val);
    onChangeInput(val, name);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {}, []);

  const elementLabel = isRequired ? label + '*' : label;
  const isActive: boolean = isFocused || !!value;
  return (
    <Box>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <FormControlLabel
          control={
            <Switch
              checked={mode === 'code'}
              onChange={() => {
                setMode(prevMode =>
                  prevMode === 'editor' ? 'code' : 'editor',
                );
              }}
              name="modeSwitch"
              color="primary"
            />
          }
          label={mode === 'editor' ? 'Switch to Code' : 'Switch to Editor'}
        />
      </Box>
      {label && (
        <label
          htmlFor={name}
          style={{
            backgroundColor: 'white',
            zIndex: 10,
            fontSize: '1.1em',
            transition: 'transform 0.2s, fontSize 0.2s',
            color: isError ? 'red' : isActive ? '#367fbb' : '#6E6E6E',
            fontWeight: 400,
          }}>
          {isError
            ? elementLabel + ' (' + errorInfo?.errorMsg + ')'
            : elementLabel}
        </label>
      )}
      {mode === 'editor' ? (
        <>
          <StyledReactQuill
            isactive={isActive}
            value={value}
            onChange={handleInputChange}
            modules={modules}
            formats={formats}
            onFocus={handleFocus}
            onBlur={handleBlur}
            theme="snow"
          />
        </>
      ) : (
        <StyledEditorTextArea
          isactive={isActive + ''}
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
    </Box>
  );
};

export default CurvedEditorElement;
