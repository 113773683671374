import {Auth} from '@aws-amplify/auth';
import {toast} from 'react-toastify';
import AsyncStorage from '@internal/utils/cache/AsyncStorage';

const UserSession = async (navigate: any) => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
    toast.dismiss();
    await AsyncStorage.clear();
    navigate('/login');
    toast.warn('User expired. Therefore, logged out', {autoClose: false});
  }
};
export default UserSession;
