import React from 'react';
import type FormSkeletonsProps from './FormSkeletonsProps';
import Skeleton from '@mui/material/Skeleton';

const FormSkeletons: React.FC<FormSkeletonsProps> = ({
  number,
  fieldHeights,
}) => {
  const Elements = () => {
    const skElements: any = [];
    for (let i = 0; i < number; i++) {
      const height: number = fieldHeights?.[i] ?? 50;
      skElements.push(
        <div key={i} style={{marginBottom: '8px'}}>
          <Skeleton
            variant="text"
            width={'100%'}
            height={height}
            style={{borderRadius: '25px', transform: 'scale(1, 1)'}}
            animation="wave"
          />
        </div>,
      );
    }
    skElements.push(
      <div key={number} style={{marginTop: '2rem'}}>
        <Skeleton
          key={number}
          variant="rectangular"
          width={'100%'}
          height={40}
          style={{borderRadius: '25px', transform: 'scale(1, 1)'}}
          animation="wave"
        />
      </div>,
    );
    return <div style={{width: '100%'}}>{skElements}</div>;
  };

  return <Elements />;
};

export default FormSkeletons;
