import React from 'react';
import styled from 'styled-components';
import {Checkbox, FormControlLabel, CircularProgress, Box} from '@mui/material';
import {
  LoginDualBox,
  LoginRightBox,
  LoginLeftBox,
  Container,
  Typography,
  Link,
  FlexContainer,
  LogoStyled,
} from '@internal/styles';
import {FormSkeletons} from '@internal/skeletons';
import {Form} from '@internal/form';
import type PagesProps from '@internal/pages/types/PagesProps';
import {toast} from 'react-toastify';
import {Auth} from '@aws-amplify/auth';

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledTypography = styled(Typography)({
  marginRight: 8,
});

const LoginPage: React.FC<PagesProps> = ({navigatePage}) => {
  const [Loading, setLoading] = React.useState(false);

  const formConfig: any = [
    {
      name: 'email',
      label: 'Email',
      fieldType: 'input',
      icon: 'email',
      isRequired: true,
      validations: {
        type: 'email',
        required: true,
        customMessages: {
          required: "Email can't be blank.",
          email: "That doesn't look like a valid email address.",
        },
      },
    },
    {
      name: 'password',
      label: 'Password',
      fieldType: 'password',
      icon: 'password',
      isRequired: true,
      validations: {
        type: 'password',
        required: true,
        minLength: 8,
        customMessages: {
          required: "Password can't be blank.",
          minLength: 'Passwords should be at least 8 characters.',
        },
      },
    },
  ];

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    toast.dismiss();
    toast.loading('Processing...');
    try {
      const user = await Auth.signIn(data.email, data.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const userAttributes = {
          name: 'Muhammad Jafer',
        };
        try {
          await Auth.completeNewPassword(user, 'Nopass@1947', userAttributes);
          // Once the challenge is completed successfully, you can redirect the user or take other actions
        } catch (error) {
          console.error('Error completing new password challenge:', error);
        }
      }
      toast.dismiss();
      setLoading(false);
    } catch (ex: any) {
      setLoading(false);
      toast.dismiss();
      toast.error('Incorrect username or password', {autoClose: false});
      return;
      /*
      if ((error+ '').includes('Incorrect username or password.')) {
        toast.error('Incorrect username or password',{autoClose:false});
        return false;
      } else if ((error + '').includes('Network error')) {
        toast.error('Unable to connect to internet',{autoClose:false});
        return false;
      } */
    }

    toast.success('Login Successful');
    navigatePage('/');
  };
  return (
    <Container>
      <LoginDualBox>
        <LoginLeftBox>
          <Box width={'300px'} style={{backgroundColor: 'blue'}}>
            <LogoStyled />
          </Box>
        </LoginLeftBox>
        <LoginRightBox>
          <Typography variant="h5" align="center" gutterBottom>
            Welcome to Startupmuster Admin Panel! 👋🏻
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Exclusively for administrators. Manage and create surveys.
          </Typography>
          <FlexContainer marginTop={'1rem'}></FlexContainer>
          {Loading && <FormSkeletons number={2} />}
          <div style={{display: Loading ? 'none' : 'block', width: '100%'}}>
            <Form
              key={0}
              fields={formConfig}
              onSubmit={handleFormSubmit}
              submitButtonText={'SIGN IN'}
              extraBeforeButtons={
                <>
                  <FlexContainer marginTop={'0rem'}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                    <Typography variant="body2" align="right">
                      <Link
                        href=""
                        onClick={() => {
                          navigatePage('/password/request/reset');
                        }}>
                        Forgot Password?
                      </Link>
                    </Typography>
                  </FlexContainer>
                  <FlexContainer marginTop={'1rem'}></FlexContainer>
                </>
              }
            />
          </div>
        </LoginRightBox>
      </LoginDualBox>
    </Container>
  );
};

export default LoginPage;
