import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import {FormGrid} from '@internal/components';
import CMSMetasAPI from '@internal/apis/CMSMetasAPI';

const FormMeta: React.FC<BasePageProps> = ({navigatePage, urlParams}) => {
  const [loading, setLoading] = React.useState(false);
  const [key, setKey] = React.useState(Date.now());
  const formConfig = React.useRef<any>([
    {
      name: 'name',
      label: 'Meta Name',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "name can't be blank.",
        },
      },
    },
    {
      name: 'contents',
      label: 'Contents',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Content can't be blank.",
        },
      },
    },
  ]);
  const record = React.useRef<any>();

  React.useEffect(() => {
    if (urlParams.id) {
      void reloadFunc().then();
    }
  }, []);
  const reloadFunc = async (): Promise<void> => {
    setLoading(true);
    record.current = await CMSMetasAPI.get(navigatePage, urlParams.id ?? '');
    for (let i = 0; i < formConfig.current.length; i++) {
      formConfig.current[i].value = record.current[formConfig.current[i].name];
    }
    setLoading(false);
    setKey(Date.now());
  };
  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    if (urlParams.id) {
      await CMSMetasAPI.update(navigatePage, record.current, data);
    } else {
      await CMSMetasAPI.save(navigatePage, data);
    }
    setLoading(false);
  };
  return (
    <React.Fragment key={key}>
      <FormGrid
        fields={formConfig.current ?? []}
        onSubmit={handleFormSubmit}
        submitButtonText={urlParams.id ? 'Update' : 'Save'}
        doShowCardHeader
        reloadFunc={urlParams.id ? reloadFunc : undefined}
        title={urlParams.id ? 'Update Page' : 'New Page'}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default FormMeta;
