import React from 'react';
import styled, {css} from 'styled-components';
import {
  CardContent,
  Box,
  Card,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CardHeader,
  Skeleton,
} from '@mui/material';
import {ExpandMore, Delete} from '@mui/icons-material';
import {StyledGridCard} from '@internal/styles';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {v4 as uuidv4} from 'uuid';
import CMSMenusAPI from '@internal/apis/CMSMenusAPI';
import type BasePageProps from '@internal/pages/BasePageProps';
import {toast} from 'react-toastify';
import CMSMenuItemsAPI from '@internal/apis/CMSMenuItemsAPI';
import CMSPagesAPI from '@internal/apis/CMSPagesAPI';
import type CMSMenuItemsTypes from '@internal/types/CMSMenuItemsTypes';

const gridPattern = css`
  background-image: repeating-linear-gradient(
      0deg,
      transparent,
      transparent 31px,
      rgba(255, 255, 255, 0.1) 31px,
      rgba(255, 255, 255, 0.1) 32px
    ),
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 31px,
      rgba(255, 255, 255, 0.1) 31px,
      rgba(255, 255, 255, 0.1) 32px
    );
  background-size: 32px 32px;
`;

const InnerCard = styled(Card)`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const DesignBox = styled(Box)`
  width: 100%;
  min-height: 80vh;
  border: 3px solid rgba(255, 255, 255, 0.1);
  position: relative;
  background-color: #2b2b2b; // Dark Background
  ${gridPattern}
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    border: 1px solid blue;
    margin-bottom: 8px; // This will create space between accordions when closed.

    &.Mui-expanded {
      margin-bottom: 8px; // Ensures spacing is maintained when expanded.
    }

    &:before {
      background-color: transparent; // Removes the default line that appears between the header and details.
    }
  }
`;

const ItemType = {
  ITEM: 'ITEM',
};

interface IItem {
  text: string;
  type?: string;
  menuItem?: string;
  link?: string;
  componentID?: string;
  sort?: number;
}

interface DraggableItemProps {
  item: IItem;
  pages: any;
  onAdd: (itemDetails: any) => void;
}

interface DroppableItemAccordionProps {
  item: IItem;
  index: number;
  page: any;
  moveItem: (fromIndex: number, toIndex: number) => void;
  onDelete: any;
}

interface DroppableAreaProps {
  incomingItems: IItem[];
  pages: any;
  saveItems: (items: any) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = ({item, pages, onAdd}) => {
  const [menuItem, setMenuItem] = React.useState('');
  const [link, setLink] = React.useState('');
  const [script, setScript] = React.useState('');
  const [componentID, setComponentID] = React.useState('');
  const [isFirstSubmit, setIsFirstSubmit] = React.useState(false);
  const [linkError, setLinkError] = React.useState(false);
  const [scriptError, setScriptError] = React.useState(false);
  const [componentIDError, setComponentIDError] = React.useState(false);
  const [menuItemError, setMenuItemError] = React.useState(false);
  const isValidLink = (url: string) => {
    const pattern =
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    return pattern.test(url);
  };

  const isValid = (): boolean => {
    let hasError = false;

    if (menuItem === '') {
      setMenuItemError(true);
      hasError = true;
    } else {
      setMenuItemError(false);
    }

    if (item.text === 'Link' && (link === '' || !isValidLink(link))) {
      setLinkError(true);
      hasError = true;
    } else {
      setLinkError(false);
    }

    if (item.text === 'Javascript' && script === '') {
      setScriptError(true);
      hasError = true;
    } else {
      setScriptError(false);
    }

    if (item.text === 'Page' && componentID === '') {
      setComponentIDError(true);
      hasError = true;
    } else {
      setComponentIDError(false);
    }

    return !hasError;
  };
  const handleAddClick = () => {
    if (!isValid()) {
      setIsFirstSubmit(true);
      return;
    }

    onAdd({
      type: item.text.toLowerCase(),
      menuItem,
      link: item.text === 'Javascript' ? script : link,
      componentID,
    });
    setMenuItem('');
    setLink('');
    setScript('');
    setComponentID('');
    setIsFirstSubmit(false);
    toast.success(menuItem + ' added');
  };
  React.useEffect(() => {
    if (isFirstSubmit) {
      isValid();
    }
  }, [menuItem, script, link, componentID]);

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        style={{borderBottom: '1px solid blue'}}>
        {item.text}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextField
              error={menuItemError}
              label={menuItemError ? 'Name (required)' : 'Name'}
              placeholder="Enter the name here" // Placeholder for added guidance
              value={menuItem}
              fullWidth
              required // Required field indication
              onChange={e => {
                setMenuItem(e.target.value);
              }}
            />
          </Grid>
          {item.text === 'Link' && (
            <Grid item>
              <TextField
                error={linkError}
                label={
                  linkError
                    ? link === ''
                      ? 'HTTP Link(required)'
                      : 'HTTP Link(invalid link)'
                    : 'HTTP Link'
                }
                placeholder="https://example.com" // Placeholder for context
                value={link}
                fullWidth
                required // Required field indication
                onChange={e => {
                  setLink(e.target.value);
                }}
              />
            </Grid>
          )}
          {item.text === 'Javascript' && (
            <Grid item>
              <TextField
                error={scriptError}
                label={scriptError ? 'Script(required)' : 'Script'}
                placeholder={'no script tags'}
                value={script}
                fullWidth
                required // Required field indication
                onChange={e => {
                  setScript(e.target.value);
                }}
              />
            </Grid>
          )}
          {item.text === 'Page' && (
            <Grid item>
              <FormControl fullWidth required>
                <InputLabel>
                  {componentIDError ? (
                    <span style={{color: '#D32F2F'}}> Page (required)</span>
                  ) : (
                    'Pages'
                  )}
                </InputLabel>
                <Select
                  error={componentIDError}
                  label={componentIDError ? 'Page(required)' : 'Page'}
                  value={componentID}
                  onChange={e => {
                    setComponentID(e.target.value);
                  }}>
                  {pages?.map((page: any, index: number) => (
                    <MenuItem key={index} value={page.id}>
                      {page.pageTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              fullWidth // Make button 100% width
              onClick={handleAddClick}>
              Add
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const DroppableItemAccordion: React.FC<DroppableItemAccordionProps> = ({
  item,
  index,
  moveItem,
  onDelete,
  page,
}) => {
  const ref = React.useRef(null);

  const [, drag] = useDrag({
    type: ItemType.ITEM,
    item: {index},
  });

  const [, drop] = useDrop({
    accept: ItemType.ITEM,
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <StyledAccordion ref={ref}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        style={{alignItems: 'center', borderBottom: '1px solid blue'}}>
        {item.menuItem} ({item.type})
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              onDelete(index);
            }}
            edge="end"
            style={{marginLeft: 'auto'}}>
            {' '}
            {/* This pushes the IconButton to the right */}
            <Delete />
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {item.type === 'link' && <div>Link: {item.link}</div>}
        {item.type === 'javascript' && (
          <div>Script: {'<script>' + item.link + '</script>'}</div>
        )}
        {item.type === 'page' && <div>Page: {page.pageTitle}</div>}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const DroppableArea: React.FC<DroppableAreaProps> = ({
  incomingItems,
  saveItems,
  pages,
}) => {
  const [items, setItems] = React.useState<any[]>([]);

  const moveItem = (fromIndex: any, toIndex: any) => {
    setItems(prev => {
      const updatedItems = [...prev];
      const [movedItem] = updatedItems.splice(fromIndex, 1);
      updatedItems.splice(toIndex, 0, movedItem);
      return updatedItems;
    });
  };
  const handleDelete = (indexToDelete: any) => {
    setItems(prevItems =>
      prevItems.filter((_, index) => index !== indexToDelete),
    );
  };

  React.useEffect(() => {
    if (incomingItems && incomingItems.length > items.length) {
      if (items.length === 0) {
        setItems(incomingItems);
        return;
      }
      setItems([...items, ...incomingItems.slice(incomingItems.length - 1)]);
    }
  }, [incomingItems]);

  React.useEffect(() => {
    saveItems(items);
  }, [items]);

  return (
    <div
      style={{
        minHeight: '200px',
        width: '100%',
        border: '1px dashed gray',
        padding: '8px',
        overflow: 'auto',
      }}>
      {items.map((item: any, index) => (
        <DroppableItemAccordion
          key={index}
          index={index}
          item={item}
          moveItem={moveItem}
          onDelete={handleDelete}
          page={pages.find((page: any) => page.id === item.componentID)}
        />
      ))}
    </div>
  );
};

const PlayMenu: React.FC<BasePageProps> = ({navigatePage, urlParams}) => {
  const [droppableItems, setDroppableItems] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const items = [
    {text: 'Link', key: uuidv4()},
    {text: 'Javascript', key: uuidv4()},
    {text: 'Page', key: uuidv4()},
  ];
  const savedRecords = React.useRef<{
    name: string;
    menuID: any;
    items: any;
    pages: any;
  }>({
    name: '',
    menuID: '',
    items: [],
    pages: [],
  });

  const menuItems = React.useRef<any>();

  const loadData = async (): Promise<void> => {
    setLoading(true);
    toast.loading('Processing...');
    const menuData = await CMSMenusAPI.get(navigatePage, urlParams.id ?? '');

    if (menuData) {
      savedRecords.current.name = menuData?.menu;
      savedRecords.current.menuID = menuData?.id;
    }
    savedRecords.current.items = await CMSMenuItemsAPI.getByMenuID(
      navigatePage,
      savedRecords.current.menuID,
    );
    savedRecords.current.pages = await CMSPagesAPI.getList(navigatePage, false);
    savedRecords.current.pages.push({id: 'survey', pageTitle: 'Survey'});
    savedRecords.current.pages.push({id: 'reports', pageTitle: 'Reports'});

    if (savedRecords.current.items && savedRecords.current.items.length > 0) {
      menuItems.current = [];
      for (let i = 0; i < savedRecords.current.items.length; i++) {
        menuItems.current.push(savedRecords.current.items[i]);
        menuItems.current[i].text = menuItems.current[i].menuItem;
      }
      setDroppableItems(menuItems.current);
    }
    setLoading(false);
  };

  const handleAddToDroppableArea = (itemDetails: any) => {
    setDroppableItems((prev: any) => [...prev, itemDetails]);
  };

  const saveMenuItems = (items: any) => {
    menuItems.current = items;
    setDroppableItems(items);
  };

  const saveAllData = async () => {
    setLoading(true);
    toast.loading('Processing...');
    const itemsNotInMenuItems = savedRecords.current.items.filter(
      (item: any) => {
        return !menuItems.current.some(
          (menuItem: any) => menuItem.id === item.id,
        );
      },
    );
    const sortedMenuItems = menuItems.current.map(
      (item: any, index: number) => ({
        ...item,
        sortNumber: index,
      }),
    );
    for (let i = 0; i < itemsNotInMenuItems.length; i++) {
      await CMSMenuItemsAPI.delete(navigatePage, itemsNotInMenuItems[i].id);
    }
    for (let i = 0; i < sortedMenuItems.length; i++) {
      const items = sortedMenuItems[i];
      items.menuID = savedRecords.current.menuID;
      delete items.text;
      if (items.id) {
        await CMSMenuItemsAPI.update(navigatePage, items);
        continue;
      }
      await CMSMenuItemsAPI.save(navigatePage, items as CMSMenuItemsTypes);
    }
    toast.dismiss();
    toast.success('Menu Items Updated');
    setLoading(false);
    navigatePage('/members/cms/menus');
  };
  React.useEffect(() => {
    void loadData().then();
  }, []);

  return (
    <>
      <Card elevation={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}>
          <Typography variant="h6">{savedRecords.current.name}</Typography>
          <Button variant="contained" color="primary" onClick={saveAllData}>
            Save
          </Button>
        </Box>
      </Card>
      <br />
      <StyledGridCard variant="outlined">
        <CardContent>
          <DesignBox>
            <DndProvider backend={HTML5Backend}>
              <Grid
                container
                spacing={3}
                style={{height: '100%', padding: '16px'}}>
                {/* Left Card */}
                <Grid item xs={6}>
                  <InnerCard>
                    <CardHeader title={'Menu Item Types'} />
                    <CardContent>
                      {loading ? (
                        <Skeleton
                          variant="rectangular"
                          width={'100%'}
                          height={180}
                        />
                      ) : (
                        <>
                          <DraggableItem
                            item={items[0]}
                            onAdd={handleAddToDroppableArea}
                            pages={savedRecords.current.pages}
                          />
                          <DraggableItem
                            item={items[1]}
                            onAdd={handleAddToDroppableArea}
                            pages={savedRecords.current.pages}
                          />
                          <DraggableItem
                            item={items[2]}
                            onAdd={handleAddToDroppableArea}
                            pages={savedRecords.current.pages}
                          />
                        </>
                      )}
                    </CardContent>
                  </InnerCard>
                </Grid>

                {/* Right Card */}
                <Grid item xs={6}>
                  <InnerCard>
                    <CardHeader title={'Menu Items'} />
                    <CardContent>
                      {loading ? (
                        <Skeleton
                          variant="rectangular"
                          width={'100%'}
                          height={250}
                        />
                      ) : (
                        <DroppableArea
                          incomingItems={droppableItems}
                          saveItems={saveMenuItems}
                          pages={savedRecords.current.pages}
                        />
                      )}
                    </CardContent>
                  </InnerCard>
                </Grid>
              </Grid>
            </DndProvider>
          </DesignBox>
        </CardContent>
      </StyledGridCard>
    </>
  );
};
export default PlayMenu;
