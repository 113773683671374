import {
  getSurveyReports,
  getSortedSurveyReports,
} from './amplify/graphql/queries';

import {
  createSurveyReports,
  deleteSurveyReports,
  updateSurveyReports,
} from './amplify/graphql/mutations';
import SurveyReportsCache from '@internal/utils/cache/SurveyReportsCache';

import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const SurveyReportsAPI = new UtilFuncAPIs({
  ref: 'SurveyReportsAPI',
  cacheFunc: SurveyReportsCache,
  filter: {
    key: 'Survey_Reports',
    sortDirection: 'ASC',
  },
  listFunc: getSortedSurveyReports,
  getFunc: getSurveyReports,
  route: '/members/survey/reports',
  insertFunc: createSurveyReports,
  updateFunc: updateSurveyReports,
  deleteFunc: deleteSurveyReports,
  backupTable: 'survey_reports',
  deleteMSG: 'Reports deleted',
  insertMSG: 'Report saved',
  updateMSG: 'Report updated',
  key: 'Survey_Reports',
  getName: 'getSurveyReports',
});

export default SurveyReportsAPI;
