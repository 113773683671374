import {createGlobalStyle} from 'styled-components';

export const GlobalStylesAdmin = createGlobalStyle`
  body {  background-color: #fdfdfd;
    font-size: 18px;
    letter-spacing: -.1px;
    font-family: 'Source Sans Pro,system-ui,-apple-system,BlinkMacSystemFont,Avenir Next,Avenir,Segoe UI,Lucida Grande,Helvetica Neue,Helvetica,Fira Sans,Roboto,Noto,Droid Sans,Cantarell,Oxygen,Ubuntu,Franklin Gothic Medium,Century Gothic,Liberation Sans,sans-serif',serif;

    overflow: hidden;
  }
  html {
    color: #44546a;
    line-height: 1.56;
  }
`;

export const GlobalStylesUI = createGlobalStyle`
  body {
    background-color: #fdfdfd;
    font-size: 18px;
    letter-spacing: -.1px;
    font-family: 'Source Sans Pro,system-ui,-apple-system,BlinkMacSystemFont,Avenir Next,Avenir,Segoe UI,Lucida Grande,Helvetica Neue,Helvetica,Fira Sans,Roboto,Noto,Droid Sans,Cantarell,Oxygen,Ubuntu,Franklin Gothic Medium,Century Gothic,Liberation Sans,sans-serif',serif;
  }

  html {
    color: #44546a;
    line-height: 1.56;
  }
`;
