import DashbaordPage from './dashbaord/DashbaordPage';
import CMSRoutes from './cms';
import SurveyRoutes from './survey';
import SystemRoutes from './system';
import RecordsRoutes from "./records";

const MemberRoutes = {
  dashboard: {
    Component: DashbaordPage,
    type: 'private',
    route: '/',
    title: 'Dashboard',
    breadcrumb: [{title: 'Home', route: '/'}],
  },
  ...CMSRoutes,
  ...SurveyRoutes,
  ...SystemRoutes,
  ...RecordsRoutes

};

export default MemberRoutes;
