import type React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import JavascriptIcon from '@mui/icons-material/Javascript';
import BuildIcon from '@mui/icons-material/Build';
import AssignmentIcon from '@mui/icons-material/Assignment';
import KeyIcon from '@mui/icons-material/Key';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
export type DrawerMenuProps = {
  type: 'text' | 'link';
  text: string;
  route?: string;
  icon?: React.ComponentType<any>;
};

const DrawerMenu: DrawerMenuProps[] = [
  {
    type: 'link',
    text: 'Dashboard',
    route: '/',
    icon: DashboardIcon,
  },
  {
    type: 'text',
    text: 'Records',
  },
  {
    type: 'link',
    text: 'Reports Downloaders',
    route: '/members/records/reports-downloaders',
    icon: DownloadForOfflineIcon,
  },
  {
    type: 'text',
    text: 'Survey',
  },
  {
    type: 'link',
    text: 'Reports',
    route: '/members/survey/reports',
    icon: AssignmentIcon,
  },
  {
    type: 'link',
    text: 'Settings',
    route: '/members/survey/settings',
    icon: SettingsIcon,
  },
  {
    type: 'text',
    text: 'CMS',
  },
  {
    type: 'link',
    text: 'Pages',
    route: '/members/cms/pages',
    icon: DescriptionIcon,
  },
  {
    type: 'link',
    text: 'Menus',
    route: '/members/cms/menus',
    icon: MenuOpenIcon,
  },
  {
    type: 'link',
    text: 'Metas',
    route: '/members/cms/metas',
    icon: AutoFixHighIcon,
  },
  {
    type: 'link',
    text: 'Scripts',
    route: '/members/cms/scripts',
    icon: JavascriptIcon,
  },
  {
    type: 'link',
    text: 'Configuration',
    route: '/members/cms/configurations',
    icon: BuildIcon,
  },
  {
    type: 'text',
    text: 'System',
  },
  {
    type: 'link',
    text: 'Secrets',
    route: '/members/system/secrets',
    icon: KeyIcon,
  },
  {
    type: 'link',
    text: 'Interval Versions',
    route: '/members/system/internal-versions',
    icon: ModelTrainingIcon,
  },
];
export default DrawerMenu;
