import styled from 'styled-components';
import Button from '@mui/material/Button';

const StyledLinkButton = styled(Button)`
  && {
    // && increases specificity, ensuring these styles take precedence
    text-transform: none; // Removes uppercase transformation of text
    color: blue;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 1rem;
    box-shadow: none;
    text-decoration: underline;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;
export default StyledLinkButton;
