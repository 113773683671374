import FormMenu from './FormMenu';
import Menus from './Menus';
import PlayMenu from './PlayMenu';

const CMSMenusRoutes = {
  cmsMenus: {
    Component: Menus,
    type: 'private',
    route: '/members/cms/menus',
    title: 'Menus',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'CMS'}],
  },
  cmsNewMenu: {
    Component: FormMenu,
    type: 'private',
    route: '/members/cms/menus/add',
    title: 'New Menu',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Menus', route: '/members/cms/menus'},
    ],
  },
  cmsUpdateMenu: {
    Component: FormMenu,
    type: 'private',
    route: '/members/cms/menus/update/:id',
    title: 'Update Menu',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Menus', route: '/members/cms/menus'},
    ],
  },
  cmsPlayMenu: {
    Component: PlayMenu,
    type: 'private',
    route: '/members/cms/menus/play/:id',
    title: 'Manage Menu Items',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Menus', route: '/members/cms/menus'},
    ],
  },
};

export default CMSMenusRoutes;
