import Secrets from './Secrets';

const SystemSecretsRoutes = {
  systemSecrets: {
    Component: Secrets,
    type: 'private',
    route: '/members/system/secrets',
    title: 'System Secrets',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'System'}],
  },
};
export default SystemSecretsRoutes;
