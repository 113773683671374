import AsyncStorage from './AsyncStorage';
import type SystemInternalConfigurationVariablesReadonlyTypes from '@internal/types/SystemInternalConfigurationVariablesReadonlyTypes';

const CacheKey = 'SystemInternalConfigurationVariablesReadonlyCache';

const SystemInternalConfigurationVariablesReadonlyCache = {
  setItem: async (value: SystemInternalConfigurationVariablesReadonlyTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<SystemInternalConfigurationVariablesReadonlyTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as SystemInternalConfigurationVariablesReadonlyTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default SystemInternalConfigurationVariablesReadonlyCache;
