import React from 'react';
import {DataGrid} from '@internal/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SystemInternalConfigurationVariablesReadonlyAPI from '@internal/apis/SystemInternalConfigurationVariablesReadonlyAPI';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import type SystemInternalConfigurationVariablesReadonlyTypes from '@internal/types/SystemInternalConfigurationVariablesReadonlyTypes';
import type BasePageProps from '@internal/pages/BasePageProps';


const InternalVersions: React.FC<BasePageProps> = ({navigatePage}) => {
  const [record, setRecord] = React.useState<SystemInternalConfigurationVariablesReadonlyTypes[]>([]);
  const loadRecords = async (refreshCache: boolean) => {
    setRecord(await SystemInternalConfigurationVariablesReadonlyAPI.getList(navigatePage, refreshCache));
  };

  return (
    <DataGrid
      sm={6}
      itemProps={[{type: 'data', key: 'variable_name', text: 'Variable'},{type: 'data', key: 'variable_value', text: 'Value'}]}
      items={record}
      itemActions={[]}
      addInfo={{
        text: 'Readonly Internal Variables',
        handler: () => {
          return;
        },
      }}
      actionID={'id'}
      loadRecords={loadRecords}
      doShowCardHeader
      doShowTableHeader
    />
  );
};
export default InternalVersions;
