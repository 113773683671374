import React from 'react';
import type SurveyOfflineProps from './SurveySubscriberReportsProps';
import {Form} from '@internal/form';
import SurveyNotifyReportEmailAPI from '@internal/apis/SurveyNotifyReportEmailAPI';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import {createPublicSurveyNotifyReportEmail} from '@internal/apis/amplify/graphql/mutations';
import CircularProgress from '@mui/material/CircularProgress';

const SurveySubscriberReports: React.FC<SurveyOfflineProps> = ({
  enableForm,
  successMsg,
  googleKey,
}) => {
  const [loading, setLoading] = React.useState(false);
  const doShowSuccessMsg = React.useRef(false);
  const dataForm = React.useRef()
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(true);
  const formConfig = [
    {
      name: 'email',
      label: 'Email',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Email can't be blank.",
        },
      },
    },
  ];
  const submitForm = async (data: any) => {
    setRefreshReCaptcha(true);
    dataForm.current = data
    setLoading(true);
  }
  const processFormWithToken = async (token:string) => {
    setRefreshReCaptcha(false);
    let data:any = dataForm.current;
    data.token = token;
    data.key = 'Survey_Notify_Report_Email';
    data.createdAt = new Date().toISOString();
    data.updatedAt = new Date().toISOString();
    console.log(data)
    const response = await SurveyNotifyReportEmailAPI.custom_call(
      null,
      createPublicSurveyNotifyReportEmail,
      {args: data},
      'createPublicSurveyNotifyReportEmail',
    );
    if (response === 'Ok') {
      doShowSuccessMsg.current = true;
    }

    setLoading(false);
    setRefreshReCaptcha(false);
  };

  if (!enableForm) return <></>;
  return (
    <>
      {doShowSuccessMsg.current ? (
        <div dangerouslySetInnerHTML={{__html: successMsg}} />
      ) : (
        <>
          <div style={{maxWidth: '500px'}}>
            <GoogleReCaptchaProvider reCaptchaKey={googleKey}>
              {!loading ? (
                <Form
                  fields={formConfig as any}
                  onSubmit={submitForm}
                  submitButtonText={'Subscribe'}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}>
                  <CircularProgress />

                  <GoogleReCaptcha
                    onVerify={async (token:string) => {
                      await processFormWithToken(token);
                    }}
                    refreshReCaptcha={refreshReCaptcha}
                  />
                </div>
              )}
            </GoogleReCaptchaProvider>
          </div>
        </>
      )}
    </>
  );
};

export default SurveySubscriberReports;
