import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import {FormGrid} from '@internal/components';

import SystemGlobalSecretsAPI from '@internal/apis/SystemGlobalSecretsAPI';

const FormConfiguration: React.FC<BasePageProps> = ({
  navigatePage,
  urlParams,
}) => {
  const [loading, setLoading] = React.useState(true);
  const dataSecret = React.useRef({} as any);
  const baseSecret = React.useRef([] as any);
  const formConfig = React.useRef<any>([
    {
      name: 'googleSecretKey',
      label: 'Google Secret Key',
      fieldType: 'input',
      isRequired: false,
      value: '',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Google Secret Key can't be blank.",
        },
      },
    },
  ]);

  React.useEffect(() => {
    void reloadFunc().then();
  }, []);
  const reloadFunc = async (): Promise<void> => {
    setLoading(true);

    baseSecret.current = await SystemGlobalSecretsAPI.getList(
      navigatePage,
      true,
    );
    baseSecret.current.map((configuration: any) => {
      dataSecret.current[configuration.config] = configuration.record;
      return configuration;
    });
    formConfig.current.map((field: any) => {
      field.value = dataSecret.current[field.name] ?? field.value;
      return field;
    });
    setLoading(false);
  };

  const submitData = (data: any) => {
    const dataValues: any = Object.entries(data);
    dataValues.forEach(async ([key, value]: any, index: number) => {
      const input = {
        config: key,
        record: value,
      };
      if (dataSecret.current?.[key]) {
        await SystemGlobalSecretsAPI.update(
          navigatePage,
          baseSecret.current.find((configuration: any) => {
            return configuration.config === key;
          }),
          input,
        );
      } else {
        await SystemGlobalSecretsAPI.save(navigatePage, input);
      }
      if (index + 1 === dataValues.length) {
        setLoading(false);
      }
    });
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    submitData(data);
  };

  return (
    <React.Fragment>
      <FormGrid
        fields={formConfig.current ?? []}
        onSubmit={handleFormSubmit}
        submitButtonText={'Save'}
        doShowCardHeader
        reloadFunc={reloadFunc}
        title={'System Secrets'}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default FormConfiguration;
