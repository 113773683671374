import React from 'react';
import type PrivateRouteProps from './PrivateRouteProps';
import PrivateNavbar from './PrivateNavbar';
import StyledPrivateContainer from '../../styles/StyledPrivateContainer';
import {MainContainer, StyledLinkButton} from '@internal/styles';
import Drawer from './Drawer';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Card, CardContent, Typography} from '@mui/material';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  navigate,
  Component,
  type,
  path,
  title,
  breadcrumb,
  urlParams,
}) => {
  const [openSideBar, setOpenSideBar] = React.useState(false);
  const toggleSideBar = () => {
    setOpenSideBar(!openSideBar);
  };
  return (
    <MainContainer>
      <Drawer
        navigate={navigate}
        toggleParentSideBar={toggleSideBar}
        openSideBar={openSideBar}
      />
      <StyledPrivateContainer>
        <PrivateNavbar navigate={navigate} toggleSideBar={toggleSideBar} />
        <Box style={{marginTop: '70px'}}>
          {breadcrumb && (
            <Box marginBottom="1.5rem">
              <Card elevation={3}>
                <CardContent>
                  <Breadcrumbs aria-label="breadcrumb">
                    {breadcrumb.map((item, index) => (
                      <span key={index}>
                        {item.route ? (
                          <StyledLinkButton
                            variant="text"
                            color="primary"
                            onClick={() => {
                              navigate(item.route);
                            }}>
                            {item.title}
                          </StyledLinkButton>
                        ) : (
                          <Typography color="textPrimary">
                            {item.title}
                          </Typography>
                        )}
                      </span>
                    ))}
                    <Typography color="textPrimary">{title}</Typography>
                  </Breadcrumbs>
                </CardContent>
              </Card>
            </Box>
          )}
          <Component
            type={type}
            navigatePage={navigate}
            path={path}
            urlParams={urlParams}
          />
        </Box>
      </StyledPrivateContainer>
    </MainContainer>
  );
};

export default PrivateRoute;
