import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PageRoutes from '@internal/pages';
import InternalRoutes from '@internal/routes/Routes';
import {ToastContainer} from 'react-toastify';
import {BrowserRouter} from 'react-router-dom';
import UIRoutes from '@internal/routes/ui-route/UIRoutes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const system = process.env.REACT_APP_System ?? 'ui';
if (process.env.NODE_ENV === 'production') {
    console.log = console.error = console.warn = console.info = console.debug = () => {};
}
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {system === 'admin' ? (
        <App
          system={system}
          pageRoutes={PageRoutes}
          InternalRoutes={InternalRoutes}
        />
      ) : (
        <UIRoutes system={system} />
      )}
    </BrowserRouter>

    <ToastContainer />
  </React.StrictMode>,
);
