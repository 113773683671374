import NotFoundPage from './not-found/NotFoundPage';

const PublicRoutes = {
  NotFound: {
    Component: NotFoundPage,
    type: 'public',
    route: '*',
  },
};

export default PublicRoutes;
