import {styled} from 'styled-components';
import Box from '@mui/material/Box';
type PageHeaderBoxProps = {
  bgimage?: string;
};
const PageHeaderTitleBox = styled(Box)<PageHeaderBoxProps>`
  color: #fff;
  height: 9.9375rem;
  padding: 1.25rem 0 0;
  background: ${({bgimage}) => `#367fbb url(${bgimage}) repeat-x bottom`};
  background-size: auto 131px;
`;
export default PageHeaderTitleBox;
