import {
  getSortedSystemGlobalSecrets,
  listSystemGlobalSecrets,
} from './amplify/graphql/queries';

import {
  createSystemGlobalSecrets,
  deleteSystemGlobalSecrets,
  updateSystemGlobalSecrets,
} from './amplify/graphql/mutations';
import SystemGlobalSecretsCache from '@internal/utils/cache/SystemGlobalSecretsCache';

import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const SystemGlobalSecretsAPI = new UtilFuncAPIs({
  ref: 'CMSConfigurationsAPI',
  cacheFunc: SystemGlobalSecretsCache,
  filter: {
    key: 'System_Global_Secrets',
  },
  listFunc: listSystemGlobalSecrets,
  getFunc: getSortedSystemGlobalSecrets,
  route: '/members/system/secrets',
  insertFunc: createSystemGlobalSecrets,
  updateFunc: updateSystemGlobalSecrets,
  deleteFunc: deleteSystemGlobalSecrets,
  backupTable: 'system_global_secrets',
  deleteMSG: 'Secret deleted',
  insertMSG: 'Secret saved',
  updateMSG: 'Secret updated',
  key: 'System_Global_Secrets',
  getName: 'getSortedSystemGlobalSecrets',
});

export default SystemGlobalSecretsAPI;
