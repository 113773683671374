import AsyncStorage from './AsyncStorage';
import type CMSMetasTypes from '@internal/types/CMSMetasTypes';

const CacheKey = 'CMSMetaTypesCache';

const CMSMetasCache = {
  setItem: async (value: CMSMetasTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<CMSMetasTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as CMSMetasTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default CMSMetasCache;
