import React, {useState} from 'react';
import {StyledEditorTextArea} from '@internal/styles';
import type CurvedTextAreaElementProps from './CurvedTextAreaElementProps';
import Box from '@mui/material/Box';

const CurvedTextAreaElement: React.FC<CurvedTextAreaElementProps> = ({
  label,
  errorInfo,
  isRequired,
  icon,
  onChange,
  onChangeInput,
  initialValue,
  name,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const isError = errorInfo?.hasError;

  const handleInputChange = (e: any) => {
    let val = e;
    if (e.target) {
      val = e.target.value;
    }
    setValue(val);
    onChangeInput(val, name);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const elementLabel = isRequired ? label + '*' : label;
  const isActive: boolean = isFocused || !!value;

  return (
    <Box>
      <br />
      {label && (
        <label
          htmlFor={name}
          style={{
            backgroundColor: 'white',
            zIndex: 10,
            fontSize: '1.1em',
            transition: 'transform 0.2s, fontSize 0.2s',
            color: isError ? 'red' : isActive ? '#367fbb' : '#6E6E6E',
            fontWeight: 400,
          }}>
          {isError
            ? elementLabel + ' (' + errorInfo?.errorMsg + ')'
            : elementLabel}
        </label>
      )}

      <StyledEditorTextArea
        isactive={isActive + ''}
        value={value}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Box>
  );
};
export default CurvedTextAreaElement;
