import {styled} from 'styled-components';
import {AppBar} from '@mui/material';

const AppBarStyle = styled(AppBar)`
  background: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
`;

export default AppBarStyle;
