import {
  getCMSConfigurations,
  listCMSConfigurations,
} from './amplify/graphql/queries';

import {
  createCMSConfigurations,
  deleteCMSConfigurations,
  updateCMSConfigurations,
} from './amplify/graphql/mutations';
import CMSConfigurationsCache from '@internal/utils/cache/CMSConfigurationsCache';

import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const CMSConfigurationsAPI = new UtilFuncAPIs({
  ref: 'CMSConfigurationsAPI',
  cacheFunc: CMSConfigurationsCache,
  filter: {
    key: 'CMS_Configurations',
  },
  listFunc: listCMSConfigurations,
  getFunc: getCMSConfigurations,
  route: '/members/cms/configurations',
  insertFunc: createCMSConfigurations,
  updateFunc: updateCMSConfigurations,
  deleteFunc: deleteCMSConfigurations,
  backupTable: 'cms_configurations',
  deleteMSG: 'Configuration deleted',
  insertMSG: 'Configuration saved',
  updateMSG: 'Configuration updated',
  key: 'CMS_Configurations',
  getName: 'getCMSConfigurations',
});
export default CMSConfigurationsAPI;
