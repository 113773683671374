import React from 'react';
import type FieldConfig from './FieldConfig';
import {
  CurvedTextElement,
  CurvedSelectElement,
  CurvedTextAreaElement,
  CurvedEditorElement,
  CurvedFileElement,
} from '../elements';

const FormField: React.FC<{
  config: FieldConfig;
  value: string;
  error?: string | null;
  setValue: (value: string, name: string) => void;
}> = ({config, value, error, setValue}) => {
  switch (config.fieldType) {
    case 'editor':
      return (
        <CurvedEditorElement
          label={config.label}
          name={config.name}
          initialValue={value}
          errorInfo={{
            hasError: !!error,
            errorMsg: error ?? '',
          }}
          onChangeInput={(value: string, name: string) => {
            setValue(value, name);
          }}
          isRequired={config?.isRequired}
        />
      );
    case 'input':
    case 'password':
      return (
        <CurvedTextElement
          label={config.label}
          name={config.name}
          errorInfo={{
            hasError: !!error,
            errorMsg: error ?? '',
          }}
          inputType={config.fieldType}
          value={value}
          onChangeInput={(value: string, name: string) => {
            setValue(value, name);
          }}
          icon={config?.icon}
          isRequired={config?.isRequired}
        />
      );
    case 'file':
      return (
        <CurvedFileElement
          label={config.label}
          name={config.name}
          errorInfo={{
            hasError: !!error,
            errorMsg: error ?? '',
          }}
          value={value}
          onChangeInput={(value: string, name: string) => {
            setValue(value, name);
          }}
          icon={config?.icon}
          isRequired={config?.isRequired}
          fileType={config?.fileType}
        />
      );
    case 'select':
      return (
        <CurvedSelectElement
          label={config.label}
          name={config.name}
          value={value}
          errorInfo={{
            hasError: !!error,
            errorMsg: error ?? '', // Joining the errors to form a string
          }}
          isRequired={config?.isRequired}
          onChangeSelect={(value: string, name: string) => {
            setValue(value, name);
          }}
          options={config.options ?? []}
        />
      );
    case 'textarea':
      return (
        <CurvedTextAreaElement
          label={config.label}
          name={config.name}
          value={value}
          errorInfo={{
            hasError: !!error,
            errorMsg: error ?? '', // Joining the errors to form a string
          }}
          isRequired={config?.isRequired}
          icon={config?.icon}
          initialValue={value}
          onChangeInput={(value: string, name: string) => {
            setValue(value, name);
          }}
        />
      );
    /*
    case 'checkbox':
      return (
        <CurvedCheckboxElement.test.tsx
          label={config.label}
          checked={!!value}
          onChange={e => {
            setValue(String(e.target.checked));
          }}
        />
      );
    // Assuming CurvedRadioElement exists too, similar to the provided components
    case 'radio':
      return (
        <div>
          {config.options?.map(option => (
            <FormControlLabel
              key={option}
              value={option}
              control={<CurvedRadioElement />}
              label={option}
              checked={value === option}
              onChange={() => {
                setValue(option);
              }}
            />
          ))}
        </div>
      ); */
    default:
      return null;
  }
};
export default FormField;
