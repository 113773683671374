import {getCMSMetas, getSortedCMSMetas} from './amplify/graphql/queries';

import {
  createCMSMetas,
  deleteCMSMetas,
  updateCMSMetas,
} from './amplify/graphql/mutations';
import CMSMetasCache from '@internal/utils/cache/CMSMetasCache';
import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const CMSMetasAPI = new UtilFuncAPIs({
  ref: 'CMSMetasAPI',
  cacheFunc: CMSMetasCache,
  filter: {
    key: 'CMS_Metas',
    sortDirection: 'ASC',
  },
  listFunc: getSortedCMSMetas,
  getFunc: getCMSMetas,
  route: '/members/cms/metas',
  insertFunc: createCMSMetas,
  updateFunc: updateCMSMetas,
  deleteFunc: deleteCMSMetas,
  backupTable: 'cms_metas',
  deleteMSG: 'Meta deleted',
  insertMSG: 'Meta saved',
  updateMSG: 'Meta updated',
  key: 'CMS_Metas',
  getName: 'getCMSMetas',
});

export default CMSMetasAPI;
