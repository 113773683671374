import AsyncStorage from './AsyncStorage';
import type SurveyReportsTypes from '@internal/types/SurveyReportsTypes';

const CacheKey = 'SurveyReportsCache';

const SurveyReportsCache = {
  setItem: async (value: SurveyReportsTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<SurveyReportsTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as SurveyReportsTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default SurveyReportsCache;
