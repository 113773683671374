import React from 'react';
import styled from 'styled-components';
import {Checkbox, FormControlLabel} from '@mui/material';
import {
  LoginDualBox,
  LoginRightBox,
  LoginLeftBox,
  Container,
  Button,
  Typography,
  Link,
  FlexContainer,
  LogoStyled,
} from '@internal/styles';
import {Form} from '@internal/form';
import type PagesProps from '@internal/pages/types/PagesProps';
import {Auth} from '@aws-amplify/auth';
import {toast} from 'react-toastify';

const ResetPasswordPage: React.FC<PagesProps> = ({navigatePage}) => {
  const formConfig: any = [
    {
      name: 'email',
      label: 'Email',
      fieldType: 'input',
      icon: 'email',
      isRequired: true,
      validations: {
        type: 'email',
        required: true,
        customMessages: {
          required: "Email can't be blank.",
          email: "That doesn't look like a valid email address.",
        },
      },
    },
  ];

  const handleFormSubmit = async (data: any) => {
    toast.dismiss();
    try {
      await Auth.forgotPassword(data.email);
    } catch (ex: any) {
      console.log('Form.test.tsx data:', ex);
    }

    toast.info(
      'Your request has been processed. If you are registered user, you should recieve an email',
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
      },
    );
  };
  return (
    <Container>
      <LogoStyled />

      <LoginDualBox>
        <LoginLeftBox />
        <LoginRightBox>
          <Typography variant="h5" align="center" gutterBottom>
            Welcome to Startupmuster Admin Panel! 👋🏻
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Exclusively for administrators. Manage and create surveys.
          </Typography>
          <FlexContainer marginTop={'1rem'}>
            <Form
              fields={formConfig}
              onSubmit={handleFormSubmit}
              submitButtonText={'RESET PASSWORD'}
              extraBeforeButtons={
                <>
                  <FlexContainer marginTop={'0rem'}>
                    <FormControlLabel control={<></>} label="" />
                    <Typography variant="body2" align="right">
                      <Link
                        href=""
                        onClick={() => {
                          navigatePage('/login');
                        }}>
                        Login
                      </Link>
                    </Typography>
                  </FlexContainer>
                  <FlexContainer marginTop={'1rem'}></FlexContainer>
                </>
              }
            />
          </FlexContainer>
        </LoginRightBox>
      </LoginDualBox>
    </Container>
  );
};

export default ResetPasswordPage;
