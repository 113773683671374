import LoginPage from './login/LoginPage';
import ResetPasswordPage from './reset-password/ResetPasswordPage';

const ProtectedRoutes = {
  Login: {
    Component: LoginPage,
    type: 'protected',
    route: '/login',
    title: 'Login',
  },
  ResetPassword: {
    Component: ResetPasswordPage,
    type: 'protected',
    route: '/password/request/reset',
    title: 'Forgot Password',
  },
};

export default ProtectedRoutes;
