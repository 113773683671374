import React from 'react';
import {DataGrid} from '@internal/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CMSMenusAPI from '@internal/apis/CMSMenusAPI';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import type CMSMenusTypes from '@internal/types/CMSMenusTypes';
import type BasePageProps from '@internal/pages/BasePageProps';

const Menus: React.FC<BasePageProps> = ({navigatePage}) => {
  const [record, setRecord] = React.useState<CMSMenusTypes[]>([]);
  const loadRecords = async (
    refreshCache: boolean,
    page: number,
  ): Promise<number> => {
    setRecord(await CMSMenusAPI.getList(navigatePage, refreshCache, 1));
    return await CMSMenusAPI.getPageNumbers();
  };

  return (
    <DataGrid
      sm={6}
      itemProps={[{type: 'data', key: 'menu', text: 'menu'}]}
      items={record}
      itemActions={[
        {
          icon: <EditIcon color="primary" />,
          handler: async (id: any) => {
            navigatePage('/members/cms/menus/update/' + id);
          },
        },
        {
          icon: <DeleteIcon color="primary" />,
          handler: async (id: any) => {
            if (await CMSMenusAPI.delete(navigatePage, id)) {
              await loadRecords(false, 1);
            }
          },
        },
        {
          icon: <PlayArrowIcon color="primary" />,
          handler: async (id: any) => {
            navigatePage('/members/cms/menus/play/' + id);
          },
        },
      ]}
      addInfo={{
        text: 'Menu',
        handler: () => {
          navigatePage('add');
        },
      }}
      actionID={'id'}
      loadRecords={loadRecords}
      doShowCardHeader
      doShowTableHeader
    />
  );
};

export default Menus;
