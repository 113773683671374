import {styled} from 'styled-components';
import Box from '@mui/material/Box';

const PageContents = styled(Box)`
  margin: 5rem 2.5rem;
  font-size: 18px;
  min-height: 50vh;
  @media (max-width: 720px) {
    margin: 2.5rem 0.625rem;
  }
`;
export default PageContents;
