import React, {useState} from 'react';
import type FormGridProps from './FormGridProps';
import {CardHeader, CardContent, IconButton, keyframes} from '@mui/material';
import {StyledGridCard} from '@internal/styles';

import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Form} from '@internal/form';
import {FormSkeletons} from '@internal/skeletons';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const FormGrid: React.FC<FormGridProps> = ({
  doShowCardHeader,
  title,
  loading,
  reloadFunc,
  ...props
}) => {
  const [isRotating, setIsRotating] = useState(false);
  const [formKey, setFormKey] = useState(0);
  React.useEffect(() => {
    setIsRotating(loading);
    if (formKey === 0 && !loading) setFormKey(Math.random());
  }, [loading]);

  const MyCardHeader = () => {
    return (
      <CardHeader
        avatar={<Box sx={{display: 'flex', alignItems: 'center'}}>{title}</Box>}
        action={
          !reloadFunc ? undefined : (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <IconButton
                onClick={reloadFunc}
                sx={{
                  animation: isRotating
                    ? `${rotateAnimation} 1s linear infinite`
                    : 'none',
                }}>
                <RefreshIcon />
              </IconButton>
            </Box>
          )
        }
      />
    );
  };

  return (
    <StyledGridCard variant="outlined">
      {doShowCardHeader && <MyCardHeader />}
      <CardContent>
        {isRotating && (
          <FormSkeletons
            number={props.fields.length}
            fieldHeights={[50, 50, 50, 500]}
          />
        )}
        <div style={{display: isRotating ? 'none' : 'block', width: '100%'}}>
          <Form key={formKey} {...props} />
        </div>
      </CardContent>
    </StyledGridCard>
  );
};
export default FormGrid;
