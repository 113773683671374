import React from 'react';
import {Routes, Route} from 'react-router-dom';
import type AppProps from './AppProps';
import Amplify from '@aws-amplify/core';
import config from '@internal/apis/aws-exports';
import 'react-toastify/dist/ReactToastify.css';
Amplify.configure(config);

const App: React.FC<AppProps> = ({InternalRoutes, pageRoutes, system}) => {
  return (
    <>
      <Routes>
        {Object.values(pageRoutes).map((routeInfo: any) => (
          <Route
            key={routeInfo.route}
            path={routeInfo.route}
            element={<InternalRoutes {...routeInfo} system={system} />}
          />
        ))}
      </Routes>
    </>
  );
};
export default App;
