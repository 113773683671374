import {API} from 'aws-amplify';

async function GoogleEmailCaptchaDownload() {
  try {
    const response = await API.post(
      'EmailDownloadPublicWithGoogleCaptcha',
      '/',
      {
        body: {},
      },
    );
    console.log(response);
  } catch (error) {
    console.error('Error calling Lambda function:', error);
  }
}
export default GoogleEmailCaptchaDownload;
