import styled from 'styled-components';

const LogoImageStyled = styled.img`
  width: 9.375rem;
  margin: 0;
  max-width: 100%;
  overflow-clip-margin: content-box;
  overflow: clip;
  border-style: none;
`;
export default LogoImageStyled;
