import {styled} from 'styled-components';
import Box from '@mui/material/Box';
const HeaderBurger = styled(Box)`
  z-index: 16;
  margin-right: 1.25rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    width: 7rem;
  }
`;
export default HeaderBurger;
