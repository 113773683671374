import React from 'react';
import {DataGrid} from '@internal/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CMSPagesAPI from '@internal/apis/CMSPagesAPI';
import type CMSPagesTypes from '@internal/types/CMSPagesTypes';
import type BasePageProps from '@internal/pages/BasePageProps';

const Pages: React.FC<BasePageProps> = ({navigatePage}) => {
  const [record, setRecord] = React.useState<CMSPagesTypes[]>([]);
  const loadRecords = async (
    refreshCache: boolean,
    page: number,
  ): Promise<number> => {
    setRecord(await CMSPagesAPI.getList(navigatePage, refreshCache, page));
    return await CMSPagesAPI.getPageNumbers();
  };

  return (
    <DataGrid
      sm={4}
      itemProps={[
        {type: 'data', key: 'pageTitle', text: 'Page'},
        {type: 'data', key: 'pageURL', text: 'url'},
      ]}
      items={record}
      itemActions={[
        {
          icon: <EditIcon color="primary" />,
          handler: async (id: any) => {
            navigatePage('/members/cms/pages/update/' + id);
          },
        },
        {
          icon: <DeleteIcon color="primary" />,
          handler: async (id: any) => {
            if (await CMSPagesAPI.delete(navigatePage, id)) {
              await loadRecords(false, 1);
            }
          },
        },
      ]}
      addInfo={{
        text: 'Page',
        handler: () => {
          navigatePage('add');
        },
      }}
      actionID={'id'}
      loadRecords={loadRecords}
      doShowCardHeader
      doShowTableHeader
    />
  );
};

export default Pages;
