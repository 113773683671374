import React from 'react';
import Amplify from '@aws-amplify/core';
import config from '@internal/apis/aws-exports';
import {
  FullScreenContainer,
  FullScreenSkeleton,
  CenteredLinearProgress,
  Header,
  GlobalStylesUI,
  LogoImageStyled as LogoStyled,
  Footer,
  FooterBox,
  LogoSisterFooterBox,
  MenuFooterLink,
  CopyRight,
  PageHeaderTitleBox,
  PageTitleTypography,
  PageContents,
} from '@internal/styles';
import CMSPagesAPI from '@internal/apis/CMSPagesAPI';
import CMSMetasAPI from '@internal/apis/CMSMetasAPI';
import ContentPage from '@internal/pages/public/content-page/ContentPage';
import ReportsPage from '@internal/pages/public/reports/ReportsPage';
import SurveyPage from '@internal/pages/public/survey/SurveyPage';
import SurveySettingsAPI from '@internal/apis/SurveySettingsAPI';
import CMSHeadScriptsAPI from '@internal/apis/CMSHeadScriptsAPI';
import CMSConfigurationsAPI from '@internal/apis/CMSConfigurationsAPI';
import CMSMenuItemsAPI from '@internal/apis/CMSMenuItemsAPI';
import {Helmet} from 'react-helmet';
import InternalRoutes from '@internal/routes/Routes';
import App from 'src/App';
import UIGlobalAppBar from './UIGlobalAppBar/UIGlobalAppBar';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import {useNavigate} from 'react-router-dom';
import type UIRoutesProps from './UIRoutesProps';
import AsyncStorage from '@internal/utils/cache/AsyncStorage';
import '@internal/assets/SM001_CREATIVE_ASSETS_2.png';
import skyline from '@internal/assets/skyline.png';
import Box from '@mui/material/Box';

Amplify.configure(config);

const UIRoutes: React.FC<UIRoutesProps> = ({system}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const loadingData = React.useRef({
    pageRoutes: {} as any,
    metas: [] as any,
    scripts: [] as any,
    configurations: {} as any,
    settings: {} as any,
    topMenuItems: [] as any,
    bottomMenuItems: [] as any,
  } as any);

  const scriptRender = (script: string) => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 10; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const scriptElement = document.createElement('script');
    const func = 'scriptRender_' + result;
    scriptElement.innerHTML = 'function ' + func + '(){' + script + '}';
    document.head.appendChild(scriptElement);
    (window as any)[func]();
    document.head.removeChild(scriptElement);
  };

  const loadMenuItems = (rawMenuItems: any) => {
    if (!rawMenuItems) {
      return [];
    }
    const menuItems = [];
    for (let i = 0; i < rawMenuItems.length; i++) {
      if (rawMenuItems[i].type === 'page') {
        const link: any = Object.values(loadingData.current.pageRoutes).find(
          (route: any) => {
            return route.menuItemID === rawMenuItems[i].componentID;
          },
        );
        menuItems.push({
          text: rawMenuItems[i].menuItem,
          func: navigate,
          link: link.route,
        });
      } else if (rawMenuItems[i].type === 'link') {
        menuItems.push({
          text: rawMenuItems[i].menuItem,
          link: rawMenuItems[i].link,
        });
      } else if (rawMenuItems[i].type === 'javascript') {
        menuItems.push({
          text: rawMenuItems[i].menuItem,
          func: scriptRender,
          link: rawMenuItems[i].link,
        });
      }
    }
    return menuItems;
  };
  const loadData = async () => {
    await AsyncStorage.clear();
    const data = await CMSPagesAPI.getList(null, true);
    loadingData.current.pageRoutes = {};

    for (let i = 0; i < data.length; i++) {
      loadingData.current.pageRoutes[data[i].pageTitle] = {
        Component: ContentPage,
        menuItemID: data[i].id,
        type: 'public',
        route: data[i].pageURL,
        title: data[i].pageTitle,
        params: data[i],
      };
    }
    const reportSetting = await SurveySettingsAPI.getList(null, true);

    loadingData.current.pageRoutes.reports = {
      Component: ReportsPage,
      menuItemID: 'reports',
      type: 'public',
      route: '',
      title: 'Reports',
      params: {reportSetting},
    };
    loadingData.current.pageRoutes.survey = {
      Component: SurveyPage,
      menuItemID: 'survey',
      type: 'public',
      route: '',
      title: '',
      params: reportSetting,
    };
    for (let i = 0; i < reportSetting.length; i++) {
      if (reportSetting[i].name === 'pageTitle') {
        loadingData.current.pageRoutes.survey.title = reportSetting[i].setting;
      }
      if (reportSetting[i].name === 'surveyPageURL') {
        loadingData.current.pageRoutes.survey.route = reportSetting[i].setting;
      }
      if (reportSetting[i].name === 'surveyReportURL') {
        loadingData.current.pageRoutes.reports.route = reportSetting[i].setting;
      }

      loadingData.current.settings[reportSetting[i].name] =
        reportSetting[i].setting;
    }
    loadingData.current.pageRoutes.survey.params = loadingData.current.settings;
    loadingData.current.metas = await CMSMetasAPI.getList(null, true);
    loadingData.current.scripts = await CMSHeadScriptsAPI.getList(null, true);
    const configurations = await CMSConfigurationsAPI.getList(null, true);
    for (let i = 0; i < configurations.length; i++) {
      let record = configurations[i].record;
      if (record.includes('image/')) {
        const fileParse = JSON.parse(record);
        record = fileParse.file;
      }
      loadingData.current.configurations[configurations[i].config] = record;
    }
    loadingData.current.pageRoutes.reports.params = {
      settings: loadingData.current.settings,
      configurations: loadingData.current.configurations,
      pageTitle: 'Reports',
    };
    loadingData.current.pageRoutes.survey.params.configurations =
      loadingData.current.configurations;

    loadingData.current.topMenuItems = loadMenuItems(
      await CMSMenuItemsAPI.getByMenuID(
        null,
        loadingData.current.configurations.topMenu,
      ),
    );
    loadingData.current.bottomMenuItems = loadMenuItems(
      await CMSMenuItemsAPI.getByMenuID(
        null,
        loadingData.current.configurations.bottomMenu,
      ),
    );
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src =
      'https://www.google.com/recaptcha/enterprise.js?render=' +
      loadingData.current.configurations.googleRecaptchaKey;
    document.head.appendChild(script);
    setLoading(false);
  };

  const theme = createTheme();
  React.useEffect(() => {
    void loadData().then();
  }, []);

  return (
    <>
      <Helmet>
        {loadingData.current.metas.map((record: any, index: number) => (
          <meta key={index} name={record.name} content={record.contents} />
        ))}
        {loadingData.current.scripts.map((record: any, index: number) => {
          const contentString = record.script;
          if (
            contentString.includes('<script') &&
            contentString.includes('src=')
          ) {
            const srcMatch = contentString.match(/src="([^"]+)"/);
            const asyncMatch = contentString.includes('async');
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = asyncMatch;
            script.src = srcMatch[1];
            document.head.appendChild(script);
          } else if (contentString.includes('<script')) {
            const innerContent = contentString.match(
              /<script[^>]*>([\s\S]*?)<\/script>/,
            )[1];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = innerContent;
            document.head.appendChild(script);
          } else if (contentString.includes('<noscript')) {
            const innerContent = contentString.match(
              /<noscript>([\s\S]*?)<\/noscript>/,
            )[1];
            const noscript = document.createElement('noscript');
            noscript.innerHTML = innerContent;
            document.head.appendChild(noscript);
          }
          return null;
        })}

        <title>
          {loadingData.current.configurations.websiteTitle ??
            'SurveyPage Muster'}
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href={loadingData.current.configurations.favicon}
        />
        <meta
          name="description"
          content={loadingData.current.configurations.metaDescription}
        />
      </Helmet>
      <GlobalStylesUI />
      <ThemeProvider theme={theme}>
        {loading ? (
          <>
            <PageHeaderTitleBox bgimage={skyline}>
              <PageTitleTypography variant="h1"></PageTitleTypography>
            </PageHeaderTitleBox>
            <PageContents>
              <Box
                sx={{
                  display: 'flex',
                  height: '40vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <CircularProgress />
              </Box>
            </PageContents>
            {/*
          <FullScreenContainer>
            <FullScreenSkeleton variant="rectangular" />
            <CenteredLinearProgress color="success" />
          </FullScreenContainer> */}
          </>
        ) : Object.keys(loadingData.current.configurations).length !== 0 &&
          loadingData.current.configurations.maintenance !== '1' ? (
          <>
            <Header>
              <UIGlobalAppBar
                menuItems={loadingData.current.topMenuItems}
                logo={loadingData.current.configurations.logo}
                navigate={navigate}
              />
            </Header>

            <App
              pageRoutes={loadingData.current.pageRoutes}
              InternalRoutes={InternalRoutes}
              system={system}
            />

            <Footer>
              <FooterBox>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    navigate('/');
                  }}>
                  <LogoStyled src={loadingData.current.configurations.logo} />
                </a>
                <LogoSisterFooterBox>&nbsp;</LogoSisterFooterBox>
              </FooterBox>
              <FooterBox>
                {loadingData.current.bottomMenuItems.map(
                  (items: any, index: number) => (
                    <MenuFooterLink
                      key={index}
                      onClick={() => {
                        if (items.func) {
                          items.func(items.link);
                        } else {
                          location.href = items.link;
                        }
                      }}>
                      {items.text}
                    </MenuFooterLink>
                  ),
                )}
              </FooterBox>
              <CopyRight>
                Copyright {new Date().getFullYear()} ©{' '}
                {loadingData.current.configurations.copyRights}. All rights
                reserved.
              </CopyRight>
            </Footer>
          </>
        ) : (
          <>The site is under maintenance</>
        )}
      </ThemeProvider>
    </>
  );
};

export default UIRoutes;
