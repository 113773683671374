import AsyncStorage from './AsyncStorage';
import type CMSMenusTypes from '@internal/types/CMSMenusTypes';

const CacheKey = 'CMMenusPagesCache';

const CMSMenusCache = {
  setItem: async (value: CMSMenusTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<CMSMenusTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as CMSMenusTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default CMSMenusCache;
