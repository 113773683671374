import AsyncStorage from './AsyncStorage';
import type CMSHeadScriptsTypes from '@internal/types/CMSHeadScriptsTypes';

const CacheKey = 'CMSHeadScriptsTypesCache';

const CMSHeadScriptsCache = {
  setItem: async (value: CMSHeadScriptsTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<CMSHeadScriptsTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as CMSHeadScriptsTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default CMSHeadScriptsCache;
