import styled from 'styled-components';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

export const Footer = styled.footer`
  color: #bbc5ca;
  background-color: #3c475e;
  padding: 2.5rem;
  font-size: 18px;
`;

export const FooterBox = styled(Box)`
  margin-bottom: 1.25rem;
  display: flex;
`;
export const LogoSisterFooterBox = styled(Box)`
  flex-grow: 1;
  border-bottom: 1px solid #fff3;
`;

export const MenuFooterLink = styled(Typography)`
  margin-right: 2.5rem !important;
  color: #bbc5ca;
  text-decoration: none;
  transition: color 0.15s ease;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const CopyRight = styled(Box)`
  font-size: 14px;
  opacity: 0.5;
  margin-bottom: 0;
`;
