import AsyncStorage from './AsyncStorage';
import type SurveySettingsTypes from '@internal/types/SurveySettingsTypes';

const CacheKey = 'SurveySettingsCache';

const SurveySettingsCache = {
  setItem: async (value: SurveySettingsTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<SurveySettingsTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as SurveySettingsTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default SurveySettingsCache;
