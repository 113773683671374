import styled from 'styled-components';
interface StyledReactQuillProps {
  isactive: string;
}
const StyledEditorTextArea = styled.textarea<StyledReactQuillProps>`
  margin-top: 16px;

  margin-bottom: 8px;
  ${props => (props.isactive === 'true' ? 'border: 2px solid;' : '')};
  border-radius: 16px;
  height: 500px;
  width: 97.5%; // To take full width
  padding: 10px; // Optional: To give some spacing inside
  font-family: inherit; // To use the same font as the rest of the app
  resize: none; // To prevent the user from resizing the textarea
  border-color: ${props =>
    props.isactive === 'true' ? '#367fbb' : 'rgba(0, 0, 0, 0.23)'};
`;
export default StyledEditorTextArea;
