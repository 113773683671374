import React from 'react';
import {StyledDrawer} from '@internal/styles';
import {Logo} from '@internal/components';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  StyledListItemTextNoMenu,
  LogoBox,
  StyledListItemButtonText,
  StyledListItem,
  StyledListItemButton,
  StyledListItemText,
} from '@internal/styles/StyleDrawer';
import {DrawerMenu} from '@internal/constants';
import type DrawerProps from './DrawerProps';
import useMediaQuery from '@mui/material/useMediaQuery';

const useOutsideClick = (ref: any, callback: any) => {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      callback();
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

const Drawer: React.FC<DrawerProps> = ({
  toggleParentSideBar,
  navigate,
  openSideBar,
}) => {
  const isTabletOrSmaller = useMediaQuery('(max-width:1023px)'); // screens less than 1024px
  const drawerRef = React.useRef(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  useOutsideClick(drawerRef, () => {
    if (!openSideBar) {
      return;
    }
    setDrawerOpen(false);
    toggleParentSideBar();
  });
  React.useEffect(() => {
    setDrawerOpen(openSideBar);
  }, [openSideBar]);
  const open = isTabletOrSmaller ? drawerOpen : true;
  return (
    <StyledDrawer
      ref={drawerRef}
      variant={isTabletOrSmaller ? 'temporary' : 'permanent'}
      open={open}>
      <LogoBox>
        <Logo />
      </LogoBox>
      <nav>
        <List>
          {Object.values(DrawerMenu).map((drawer: any, index) => (
            <span key={index}>
              {drawer.type === 'text' ? (
                <StyledListItemText>
                  <StyledListItemButtonText>
                    <StyledListItemTextNoMenu primary={drawer.text} />
                  </StyledListItemButtonText>
                </StyledListItemText>
              ) : (
                <StyledListItem>
                  <StyledListItemButton
                    onClick={() => {
                      navigate(drawer.route);
                    }}>
                    <ListItemIcon>
                      <drawer.icon />
                    </ListItemIcon>
                    <ListItemText primary={drawer.text} />
                  </StyledListItemButton>
                </StyledListItem>
              )}
            </span>
          ))}
        </List>
      </nav>
    </StyledDrawer>
  );
};

export default Drawer;
