import ReactQuill from 'react-quill';
import styled from 'styled-components';

interface StyledReactQuillProps {
  isactive: boolean;
}

const StyledReactQuill = styled(ReactQuill)<StyledReactQuillProps>`
  margin-top: 16px;
  margin-bottom: 8px;
  .ql-container.ql-snow {
    ${props => (props.isactive ? 'border: 2px solid;' : '')};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    height: 500px;
    border-color: ${props =>
      props.isactive ? '#367fbb' : 'rgba(0, 0, 0, 0.23)'};
  }
  .ql-toolbar.ql-snow {
    ${props => (props.isactive ? 'border: 2px solid;' : '')};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-color: ${props =>
      props.isactive ? '#367fbb' : 'rgba(0, 0, 0, 0.23)'};
  }
  .ql-tooltip.ql-editing {
    left: 5px !important;
  }
`;

export default StyledReactQuill;
