import React, {useRef} from 'react';

import FormField from './FormField';
import {useFieldValidation} from '../validations/UseFieldValidation';
import {Button} from '@internal/styles';
import type FormProps from './FormProps';
import {toast} from 'react-toastify';

const Form: React.FC<FormProps> = ({
  fields,
  onSubmit,
  submitButtonText = 'Submit', // Defaulting to "Submit"
  showCancelButton = false, // Defaulting to false
  onCancel,
  extraBeforeForm,
  extraBeforeButtons,
  extraAfterButtons,
}) => {
  const hasSubmitted = useRef(false);
  const fieldStates = fields.map(field =>
    useFieldValidation(field.validations ?? {}, field.value ?? ''),
  );

  const handleSubmit = async () => {
    hasSubmitted.current = true;
    let allValid = true;
    const formValues: Record<string, string> = {};

    for (const fieldState of fieldStates) {
      const index = fieldStates.indexOf(fieldState);
      if (fieldState.validate()) allValid = false;
      formValues[fields[index].name] = fieldState.value;
    }

    if (allValid) {
      await onSubmit(formValues);
    } else {
      toast.info('Error(s) in form');
    }
  };
  const Field = React.useMemo(
    () => (
      <>
        {fieldStates.map((fieldState, index) => (
          <div key={index}>
            <FormField
              config={fields[index]}
              value={fieldState.value}
              error={
                fieldState.errors.length > 0 ? fieldState.errors[0] : undefined
              }
              setValue={(value: string, name: string) => {
                const index = fieldStates.findIndex(
                  (_, idx) => fields[idx].name === name,
                );
                if (fieldStates[index]) {
                  fieldStates[index].setValue(value);
                  if (hasSubmitted.current) {
                    fieldStates[index].validateByValue(value);
                  }
                }
              }}
            />
          </div>
        ))}
      </>
    ),
    [fieldStates],
  );

  return (
    <div style={{width: '100%'}}>
      {extraBeforeForm}
      {Field}
      {extraBeforeButtons}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{marginTop: '2rem'}}
          onClick={handleSubmit}>
          {submitButtonText}
        </Button>
        {showCancelButton && <button onClick={onCancel}>Cancel</button>}
      </div>
      {extraAfterButtons}
    </div>
  );
};

export default Form;
