import AsyncStorage from './AsyncStorage';
import type SurveyDownloadReportEmailTypes from '@internal/types/SurveyDownloadReportEmailTypes';

const CacheKey = 'SurveyDownloadReportEmail';

const SurveyDownloadReportEmailTypesCahce = {
  setItem: async (value: SurveyDownloadReportEmailTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<SurveyDownloadReportEmailTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items
      ? (JSON.parse(items) as SurveyDownloadReportEmailTypes[])
      : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default SurveyDownloadReportEmailTypesCahce;
