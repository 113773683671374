import Configurations from './Configurations';

const CMSConfigurationsRoutes = {
  cmsConfigurations: {
    Component: Configurations,
    type: 'private',
    route: '/members/cms/configurations',
    title: 'CMS Configuration',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'CMS'}],
  },
};

export default CMSConfigurationsRoutes;
