import Settings from './Settings';

const SurveySettingsRoutes = {
  surveySettings: {
    Component: Settings,
    type: 'private',
    route: '/members/survey/settings',
    title: 'Survey Settings',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'Survey'}],
  },
};

export default SurveySettingsRoutes;
