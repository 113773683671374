import Scripts from './Scripts';
import FormScript from './FormScript';

const CMSScriptsRoutes = {
  cmsScripts: {
    Component: Scripts,
    type: 'private',
    route: '/members/cms/scripts',
    title: 'Scripts',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'CMS'}],
  },
  cmsNewScripts: {
    Component: FormScript,
    type: 'private',
    route: '/members/cms/scripts/add',
    title: 'New Script',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Scripts', route: '/members/cms/scripts'},
    ],
  },
  cmsUpdateScripts: {
    Component: FormScript,
    type: 'private',
    route: '/members/cms/scripts/update/:id',
    title: 'Update Script',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Scripts', route: '/members/cms/scripts'},
    ],
  },
};

export default CMSScriptsRoutes;
