import styled from 'styled-components';
import Container from '@mui/material/Container';

const StyledPrivateContainer = styled(Container)`
  max-width: 100% !important;
  width: 100% !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  flex-grow: 1;
  height: 100vh !important;
  overflow: auto !important;
`;
export default StyledPrivateContainer;
