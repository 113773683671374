import React, {useState} from 'react';
import {type SelectChangeEvent} from '@mui/material';
import {
  CurvedSelect as CurvedSelectStyled,
  FormControlStyle,
} from '@internal/styles';
import type CurvedSelectElementProps from './CurvedSelectElementProps';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
const CurvedSelectElement: React.FC<CurvedSelectElementProps> = ({
  label,
  errorInfo,
  isRequired,
  onChange,
  onChangeSelect,
  name,
  options,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue ?? '');
  const isError = errorInfo?.hasError;
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as string;
    setValue(selectedValue);
    onChangeSelect(selectedValue, name);
  };

  const elementLabel = isRequired ? label + '*' : label;
  return (
    <FormControlStyle fullWidth>
      <InputLabel>
        {isError
          ? elementLabel + ' (' + errorInfo?.errorMsg + ')'
          : elementLabel}
      </InputLabel>
      <CurvedSelectStyled
        label={
          isError
            ? elementLabel + ' (' + errorInfo?.errorMsg + ')'
            : elementLabel
        }
        error={isError}
        value={value}
        variant="outlined"
        fullWidth
        onChange={handleChange}
        {...props}>
        {options?.data.map((option: any, index: number) => (
          <MenuItem key={index} value={option[options.dictionary.id]}>
            {option[options.dictionary.value]}
          </MenuItem>
        ))}
      </CurvedSelectStyled>
    </FormControlStyle>
  );
};
export default CurvedSelectElement;
