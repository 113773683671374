import {getCMSMenus, getSortedCMSMenus} from './amplify/graphql/queries';

import {
  createCMSMenus,
  deleteCMSMenus,
  updateCMSMenus,
} from './amplify/graphql/mutations';
import CMSMenusCache from '@internal/utils/cache/CMSMenusCache';
import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const CMSMenusAPI = new UtilFuncAPIs({
  ref: 'CMSMenusAPI',
  cacheFunc: CMSMenusCache,
  filter: {
    key: 'CMS_Menus',
    sortDirection: 'ASC',
  },
  listFunc: getSortedCMSMenus,
  getFunc: getCMSMenus,
  route: '/members/cms/menus',
  insertFunc: createCMSMenus,
  updateFunc: updateCMSMenus,
  deleteFunc: deleteCMSMenus,
  backupTable: 'cms_head_scripts',
  deleteMSG: 'Menu deleted',
  insertMSG: 'Menu saved',
  updateMSG: 'Menu updated',
  key: 'CMS_Menus',
  getName: 'getCMSMenus',
});

export default CMSMenusAPI;
