import styled from 'styled-components';
import {TextField, Select, Checkbox, Radio} from '@mui/material';
import FormControl from '@mui/material/FormControl';
const CurvedTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 25px;
  }
`;

const CurvedSelect = styled(Select)`
  border-radius: 25px !important;
`;
const FormControlStyle = styled(FormControl)`
  margin-top: 16px !important;
  margin-bottom: 8px !important;
`;

const CurvedTextarea = styled.textarea`
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 8px;
  width: 100%;
`;

// You can further style Checkbox and Radio if required
const CurvedCheckbox = styled(Checkbox)``;
const CurvedRadio = styled(Radio)``;

export {
  CurvedTextField,
  CurvedSelect,
  CurvedTextarea,
  CurvedCheckbox,
  CurvedRadio,
  FormControlStyle,
};
