import {Storage} from 'aws-amplify';
const blobToString = async (blob: any) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(blob);
  });
};
const FileSQLAPI = {
  update: async (newItem: any): Promise<void> => {
    /*
    try {
      const date = new Date();
      const fileName = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}.json`;

      let fileContent = [];

      // Try to retrieve the existing file
      try {
        const existingFile = await Storage.get(`CMSPage/${fileName}`, {
          download: true,
        });
        if (existingFile.Body) {
          const Body: any = await blobToString(existingFile.Body);

          fileContent = JSON.parse(Body);
        }
      } catch (error) {
        console.log('File does not exist or other error:', error);
      }
      fileContent.push(newItem);
      const result = await Storage.put(
        `CMSPage/${fileName}`,
        JSON.stringify(fileContent),
        {
          contentType: 'application/json',
        },
      );

      console.log('File uploaded successfully:', result);
    } catch (error) {
      console.error('Error processing the file:', error);
    } */
    console.log('backup disabled sql');
  },
};
export default FileSQLAPI;
