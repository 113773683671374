import Reports from './Reports';
import FormReport from './FormReport';

const SurveyReportsRoutes = {
  surveyReports: {
    Component: Reports,
    type: 'private',
    route: '/members/survey/reports',
    title: 'Reports',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'Survey'}],
  },
  surveyNewReport: {
    Component: FormReport,
    type: 'private',
    route: '/members/survey/reports/add',
    title: 'New Report',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'Survey'},
      {title: 'Reports', route: '/members/survey/reports'},
    ],
  },
  surveyUpdateReport: {
    Component: FormReport,
    type: 'private',
    route: '/members/survey/reports/update/:id',
    title: 'Update Report',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'Survey'},
      {title: 'Reports', route: '/members/survey/reports'},
    ],
  },
};

export default SurveyReportsRoutes;
