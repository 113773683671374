import React, {useCallback} from 'react';
import {CurvedCheckbox as CurvedCheckboxStyled} from '@internal/styles';
import type CurvedCheckboxElementProps from './CurvedCheckboxElementProps';

const CurvedCheckboxElement: React.FC<CurvedCheckboxElementProps> = ({
  label,
  errorInfo,
  isRequired,
  onChange,
  name,
  isChecked,
  ...props
}) => {
  // const isError = errorInfo?.hasError;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked, name);
    },
    [onChange],
  );

  return (
    <label>
      <CurvedCheckboxStyled checked={isChecked} onChange={handleChange} />
      {label}
      {isRequired && '*'}
    </label>
  );
};

export default CurvedCheckboxElement;
