import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import SurveyReportsAPI from '@internal/apis/SurveyReportsAPI';
import {
  ReportPreviousDivParent,
  ReportPreviousImage,
  ReportPreviousSpan,
  StyledTypography,
  ReportDefaultDivParent,
  ReportDefaultImage,
  ReportDefaultP,
} from '@internal/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {DownloadEmailDialog} from './components';
import GAEvents from '@internal/utils/GAEvents';

const ReportsPage: React.FC<BasePageProps> = ({
  navigatePage,
  urlParams,
  otherProps,
}) => {
  const [loading, setLoading] = React.useState(true);
  const reports = React.useRef<any>([]);
  const defaultReport = React.useRef<any>();
  const [selectedReport, setSelectedReport] = React.useState<any>();
  const loadReport = async () => {
    reports.current = await SurveyReportsAPI.getList(null, false);
    reports.current = reports.current.filter(
      (report: any) => report.reportStatus !== 'Draft',
    );

    reports.current.sort((a: any, b: any) => Number(a.year) - Number(b.year));

    reports.current.map((data: any) => {
      try {
        data.reportImage = JSON.parse(data.reportImage);
      } catch (e) {}
      return data;
    });
    const index = JSON.parse(JSON.stringify(reports.current)).findIndex(
      (data: any) => {
        return data.id === otherProps?.params.settings.defaultReport;
      },
    );

    defaultReport.current = reports.current.splice(index, 1);
    setLoading(false);
  };

  const handleOpenFile = (report: any) => {
    setSelectedReport(report);
  };

  React.useEffect(() => {
    void loadReport().then();
  }, []);
  React.useEffect(() => {
    GAEvents.setPage('Reports');
  }, []);
  if (loading) {
    return <></>;
  }
  return (
    <>
      <DownloadEmailDialog
        open={!!selectedReport}
        logo={otherProps?.params.configurations.logo}
        report={selectedReport}
        googleKey={otherProps?.params.configurations.googleRecaptchaKey}
        close={() => {
          handleOpenFile(undefined);
        }}
      />
      {defaultReport.current && defaultReport.current.length > 0 && (
        <div style={{padding: '16px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ReportDefaultDivParent
                onClick={() => {
                  handleOpenFile(defaultReport.current[0]);
                }}>
                <ReportDefaultImage
                  src={defaultReport.current[0].reportImage.file}
                  width="400"
                  alt={defaultReport.current[0].year}
                />
              </ReportDefaultDivParent>
            </Grid>

            <Grid item xs={12} md={8}>
              <ReportDefaultP
                dangerouslySetInnerHTML={{
                  __html: defaultReport.current[0].description,
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  handleOpenFile(defaultReport.current[0]);
                }}>
                Read the report
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {reports.current.length > 0 && (
        <>
          <StyledTypography variant="h3">Previous Reports</StyledTypography>
          <div style={{padding: 20}}>
            <Grid container spacing={3}>
              {reports.current.map((data: any, index: number) => (
                <Grid item xs={12} md={4} lg={4} key={index}>
                  <ReportPreviousDivParent
                    onClick={() => {
                      handleOpenFile(data);
                    }}>
                    <ReportPreviousImage
                      src={data.reportImage.file}
                      width="256"
                      alt={data.year}
                    />
                    <ReportPreviousSpan>{data.year}</ReportPreviousSpan>
                  </ReportPreviousDivParent>
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default ReportsPage;
