import React from 'react';
import type BasePageProps from '../../BasePageProps';
import {SurveySubscriberReports} from './components';

const SurveyPage: React.FC<BasePageProps> = ({otherProps}) => {
  const [data, setData] = React.useState<any>({
    isLoaded: true,
    isSurveyCompleted: '1',
    enableEmailReportSubscriber: '1',
    mailReportSubscriberThankYourMessage: '',
    surveyIntro: '',
    surveyMaintenance: '',
  });
  React.useEffect(() => {
    if (otherProps?.params) {
      setData({isLoaded: false, ...otherProps.params});
    }
  }, []);
  if (data.isLoaded) {
    return <div>Loading</div>;
  }
  if (data.isSurveyCompleted === '1') {
    return (
      <>
        <div style={{maxWidth: '800px'}}>
          <div dangerouslySetInnerHTML={{__html: data.surveyMaintenance}} />
        </div>
        <SurveySubscriberReports
          enableForm={data.enableEmailReportSubscriber === '1'}
          successMsg={data.mailReportSubscriberThankYourMessage}
          googleKey={otherProps?.params.configurations.googleRecaptchaKey}
        />
      </>
    );
  }

  return <div></div>;
};

export default SurveyPage;
