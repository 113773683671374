import React, {useState} from 'react';
import packageJson from 'package.json';
import {
  Toolbar,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  AccountCircle,
  Menu as MenuIcon,
} from '@mui/icons-material';
import styled from 'styled-components';
import {StyledAppBar, StyledToolbar} from '@internal/styles';
import {Auth} from '@aws-amplify/auth';
import {toast} from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import AsyncStorage from '@internal/utils/cache/AsyncStorage';

interface PrivateNavbarProps {
  onThemeToggle?: () => void;
  navigate: any;
  toggleSideBar: any;
}

const AdminPanelLabel = styled(Typography)`
  flex-grow: 1; // Allows the label to take up the remaining space
  color: white;
  margin-right: 16px; // To give some spacing before the icons
`;

const PrivateNavbar: React.FC<PrivateNavbarProps> = ({
  navigate,
  onThemeToggle,
  toggleSideBar,
}) => {
  const isTabletOrSmaller = useMediaQuery('(max-width:1023px)'); // screens less than 1024px
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (url: string) => {
    setAnchorEl(null);
  };
  const handleMenuLogout = async () => {
    await Auth.signOut();
    toast.info('Logout successful');
    await AsyncStorage.clear();
    navigate('/login');
  };

  return (
    <>
      <StyledAppBar position="static">
        <StyledToolbar>
          {isTabletOrSmaller && (
            <IconButton color="inherit" onClick={toggleSideBar}>
              <MenuIcon />
            </IconButton>
          )}
          <AdminPanelLabel variant="h6">
            Admin Panel (V {packageJson.version})
          </AdminPanelLabel>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            <AccountCircle />
          </IconButton>
        </StyledToolbar>
      </StyledAppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            handleMenuClose('/members/user/profile');
          }}>
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose('/members/settings');
          }}>
          Account Settings
        </MenuItem>
        <MenuItem onClick={handleMenuLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default PrivateNavbar;
