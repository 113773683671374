import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      {...({enableBackground: 'new 0 0 334.5 122.5'} as any)}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 334.5 122.5"
      xmlSpace="preserve">
      <style type="text/css">
        {`
            .st0{fill:#FFFFFF;}
            .st1{opacity:0.4;fill:#FFFFFF;}
            .st2{opacity:0.5;fill:#FFFFFF;}
            .st3{opacity:0.6;fill:#FFFFFF;}
            .st4{opacity:0.7;fill:#FFFFFF;}
            .st5{opacity:0.8;fill:#FFFFFF;}
            .st6{opacity:0.9;fill:#FFFFFF;}
            `}
      </style>
      <g>
        <path
          className="st0"
          d="M62.9,74.9h-0.1c-0.1,1.5-0.9,4.1-2,6.7l-11.5,30.1H39.1L27.3,81.1c-1-2.6-2-5.3-2-6.7h-0.1v29.9h5.3v7.4H12.3
		v-7.4h4.5V68.1h-5.3v-7.4h18.7l13.1,33.9c0.8,2,1.8,4,2,6.9h0.1c0.2-2.9,1-5,2.6-9.1l11.9-31.7h18.5v7.4h-5.3v36.2h5.1v7.4H58.6
		v-7.4h4.3V74.9z"
        />
        <path
          className="st0"
          d="M113.6,60.7h19.8v7.4h-4.6v23.3c0,15.9-6.7,21.2-21.7,21.2c-16.8,0-20.4-7.4-20.4-18.7V68.1h-4.6v-7.4H102v7.4
		h-4.6v24c0,7.4,2.2,12.2,10.4,12.2c6.8,0,11.5-2.4,11.5-12.2v-24h-5.6V60.7z"
        />
        <path
          className="st0"
          d="M166.3,68.5c-1.9-0.7-4.4-1.2-6.9-1.2c-7.1,0-9.3,3.6-9.3,6.5c0,3.7,2.7,4.8,6.1,5.6l6.1,1.5
		c7.5,1.9,15.8,4.4,15.8,15c0,9.7-6.6,16.8-21.1,16.8c-5.6,0-11.5-0.6-18.3-2.8V96.3h10.7v6.7c2.7,1.1,5.6,1.3,8.6,1.3
		c6.3,0,9-3,9-6.9c0-2.6-1.5-4.5-8.3-6.3l-5.6-1.5C142.6,87,139,82.9,139,75.1c0-8.1,5.7-15.2,20.1-15.2c6.2,0,12.4,1,16.8,2.7v12.2
		h-9.6V68.5z"
        />
        <path
          className="st0"
          d="M220.9,68.1h-9.2v36.2h4.6v7.4h-19.8v-7.4h4.6V68.1h-9.2l-0.4,7h-8.8l0.4-14.4h46.5l0.4,14.4h-8.8L220.9,68.1z
		"
        />
        <path
          className="st0"
          d="M262.7,95.7h8.8l-0.6,16h-36.7v-7.4h4.6V68.1h-5.5v-7.4h37.4l0.4,15h-8.8l-0.4-7.6h-12.5v13.4h12.8v7.9h-12.8
		v14.9h12.7L262.7,95.7z"
        />
        <path
          className="st0"
          d="M282.1,68.1h-5v-7.4h22.5c6,0,7.8,0.4,10.7,1.9c4.6,2.3,6.9,6.9,6.9,12.1c0,7.6-6.3,13-12,13.2V88
		c2.6,0.2,4,1.2,6.6,5.4l4.6,7.7c1.5,2.6,2.8,3.2,4.2,3.2h1.9v7.4h-8.2c-2.7,0-4.6-0.9-8.5-7.7l-6.2-10.7c-1-1.7-2.3-2.6-4.2-2.6
		h-2.5v13.4h4.6v7.4h-19.8v-7.4h4.6V68.1z M292.7,83h5.5c5.7,0,8.3-2.5,8.3-7.4c0-5-2.5-7.4-9-7.4h-4.8V83z"
        />
      </g>
      <rect x="234.2" y="50.4" className="st1" width="9.2" height="3.6" />
      <rect x="246.5" y="48.2" className="st2" width="9.2" height="5.9" />
      <rect x="258.7" y="43.7" className="st3" width="9.2" height="10.3" />
      <rect x="271" y="36.8" className="st4" width="9.2" height="17.3" />
      <rect x="283.2" y="30.6" className="st5" width="9.2" height="23.5" />
      <rect x="295.5" y="21.6" className="st6" width="9.2" height="32.5" />
      <rect x="307.7" y="11.1" className="st0" width="9.2" height="43" />
      <g>
        <path
          className="st0"
          d="M12.2,48.7l0.5-0.7c0.4-0.5,0.8-0.6,1.3-0.2c0.6,0.5,4.8,4.4,10.6,4.4c5.2,0,9.6-3.5,9.6-7.7
		c0-5.1-4.2-7.4-11.1-9.7c-6.6-2.3-11-4.7-11-11.2c0-4.3,3.4-10.1,12.3-10.1c5.7,0,9.8,2.9,9.8,2.9c0.2,0.2,0.6,0.6,0.2,1.2
		c-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.5-0.7,0.6-1.3,0.3c-0.2-0.1-4-2.7-8.4-2.7c-7,0-9.6,4.3-9.6,7.6c0,4.9,3.7,7,9.5,8.9
		c7.8,2.7,12.8,5.4,12.8,12c0,5.7-5.9,10.2-12.4,10.2c-6.8,0-11.4-4-12.1-4.7C12,49.6,11.7,49.3,12.2,48.7z"
        />
        <path
          className="st0"
          d="M51.6,16.5H40.7c-0.6,0-0.7-0.3-0.7-0.9v-0.9c0-0.5,0.3-0.7,0.7-0.7h24.6c0.5,0,0.7,0.3,0.7,0.7v1
		c0,0.4-0.3,0.7-0.7,0.7h-11v36.8c0,0.4-0.3,0.7-0.9,0.7h-1c-0.4,0-0.7-0.3-0.7-0.7V16.5z"
        />
        <path
          className="st0"
          d="M64.6,53.3l17.6-39.7c0.1-0.2,0.4-0.4,0.7-0.4H83c0.3,0,0.6,0.1,0.7,0.4l17.4,39.7c0.1,0.3,0.1,0.7-0.5,0.7
		h-1.5c-0.4,0-0.7-0.3-0.8-0.5L93.7,43H71.9l-4.6,10.5C67.3,53.7,67,54,66.6,54H65C64.6,54,64.4,53.8,64.6,53.3z M92.8,40.7l-9.8-23
		h-0.1l-9.9,23H92.8z"
        />
        <path
          className="st0"
          d="M107.6,14.8c0-0.4,0.3-0.7,0.7-0.7h14.2c7,0,12.7,5.3,12.7,12.2c0,5.8-4,10.4-9.4,11.9l8.3,14.8
		c0.3,0.5,0.2,1-0.4,1h-1.8c-0.4,0-0.6-0.2-0.8-0.5l-8.3-15c-0.7,0.1-1.4,0.1-2.1,0.1h-10.5v14.7c0,0.4-0.3,0.7-0.9,0.7h-1
		c-0.4,0-0.7-0.3-0.7-0.7V14.8z M122.4,36.2c5.5,0,10.1-4.3,10.1-9.9c0-5.4-4.6-9.6-10.2-9.6h-12v19.5H122.4z"
        />
        <path
          className="st0"
          d="M150.6,16.5h-10.9c-0.6,0-0.7-0.3-0.7-0.9v-0.9c0-0.5,0.3-0.7,0.7-0.7h24.6c0.5,0,0.7,0.3,0.7,0.7v1
		c0,0.4-0.3,0.7-0.7,0.7h-11v36.8c0,0.4-0.3,0.7-0.9,0.7h-1c-0.4,0-0.7-0.3-0.7-0.7V16.5z"
        />
        <path
          className="st0"
          d="M171.1,14.8c0-0.4,0.3-0.7,0.7-0.7h1.2c0.4,0,0.7,0.3,0.7,0.7v24.4c0,7.4,4.6,12.8,12.2,12.8
		c7.7,0,12.3-5.4,12.3-12.7V14.8c0-0.5,0.2-0.7,0.8-0.7h1.1c0.4,0,0.7,0.3,0.7,0.7v24.7c0,8.7-5.9,15.1-15,15.1
		c-9,0-14.9-6.4-14.9-15.1V14.8z"
        />
        <path
          className="st0"
          d="M212.6,14.8c0-0.4,0.3-0.7,0.7-0.7h12.3c7.1,0,12.9,5.8,12.9,12.8c0,7.1-5.8,13-12.8,13h-10.4v13.5
		c0,0.4-0.3,0.7-0.9,0.7h-1c-0.4,0-0.7-0.3-0.7-0.7V14.8z M225.5,37.1c5.6,0,10.3-4.6,10.3-10.4c0-5.6-4.7-10-10.4-10h-10.2v20.4
		H225.5z"
        />
      </g>
    </svg>
  );
};

export default Logo;
