import CMSMenusRoutes from './menus';
import CMSConfigurationsRoutes from './configurations';
import CMSScriptsRoutes from './scripts';
import CMSMetasRoutes from './metas';
import CMSPagesRoutes from './pages';

const CMSRoutes = {
  ...CMSPagesRoutes,
  ...CMSMenusRoutes,
  ...CMSMetasRoutes,
  ...CMSScriptsRoutes,
  ...CMSConfigurationsRoutes,
};
export default CMSRoutes;
