import UserSession from '@internal/apis/UserSessions';
import {toast} from 'react-toastify';
import {API, graphqlOperation} from 'aws-amplify';
import FileSQLAPI from '@internal/apis/SQLBackup';
import {getTableCount} from './amplify/graphql/queries';

class UtilFuncAPIs {
  ref: string;
  cacheFunc?: any;
  filter: any;
  listFunc: any;
  getFunc: any;
  deleteFunc: any;
  insertFunc: any;
  updateFunc: any;
  route: string;
  backupTable: any;
  deleteMSG: string;
  insertMSG: string;
  updateMSG: string;
  key?: string;
  getName: string;

  constructor({
    ref,
    cacheFunc,
    filter,
    getFunc,
    route,
    deleteFunc,
    insertFunc,
    updateFunc,
    backupTable,
    deleteMSG,
    insertMSG,
    updateMSG,
    key,
    listFunc,
    getName,
  }: {
    ref: string;
    cacheFunc?: any;
    filter: any;
    getFunc: any;
    route: string;
    insertFunc: any;
    updateFunc: any;
    deleteFunc: any;
    backupTable: any;
    deleteMSG: string;
    insertMSG: string;
    updateMSG: string;
    key?: string;
    listFunc: any;
    getName: string;
  }) {
    this.ref = ref;
    this.filter = filter;
    this.getFunc = getFunc;
    this.route = route;
    this.deleteFunc = deleteFunc;
    this.insertFunc = insertFunc;
    this.updateFunc = updateFunc;
    this.backupTable = backupTable;
    this.deleteMSG = deleteMSG;
    this.insertMSG = insertMSG;
    this.updateMSG = updateMSG;
    this.listFunc = listFunc;
    this.getName = getName;
    if (key) {
      this.key = key;
    }
    if (cacheFunc) {
      this.cacheFunc = cacheFunc;
    }
  }

  private handleError(error: any, data?: any) {
    toast.dismiss();
    if ((error + '').includes('Network error')) {
      toast.error('Unable to connect to internet', {autoClose: false});
    } else {
      toast.error('Unable to contact server');
    }
    console.log('error ' + this.ref + ': ', error);
  }

  private findItems(obj: any) {
    if (obj && typeof obj === 'object') {
      if (Object.prototype.hasOwnProperty.call(obj, 'items')) {
        return obj.items;
      }
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const result: any = this.findItems(obj[key]);
          if (result) return result;
        }
      }
    }
    return null;
  }

  private findNextToken(data: any) {
    // Get the first property name in the object
    const firstPropertyName = Object.keys(data)[0];

    // Get the nextToken from the first property
    const nextToken = data[firstPropertyName].nextToken;

    return nextToken;
  }

  async hasNexToken(): Promise<boolean> {
    if (this.cacheFunc) {
      const cacheData = (await this.cacheFunc.getItem()) || {
        items: [],
        nextToken: null,
      };
      return !cacheData.nextToken;
    }

    return false;
  }

  async getPageNumbers(): Promise<number> {
    // console.group('UtilFunc - getPageNumbers');
    console.log(this.cacheFunc);

    if (this.cacheFunc) {
      const cacheData = (await this.cacheFunc.getItem()) || {
        items: [],
        count: 0,
        nextToken: null,
      };
      console.log('cacheData count', cacheData.count);
      return cacheData.count + 1;
    }
    return 1;
  }

  async getList(
    navigation: any,
    refreshCache: boolean,
    page: number = 1,
    limit = 25,
  ): Promise<any[]> {
    // console.groupEnd();
    // console.group('UtilFunc - getList');
    try {
      if (navigation) {
        await UserSession(navigation);
      }
      // console.log('next token:', goNextToken);

      let cacheData = {items: [], count: 0, nextToken: null};
      // console.log('refreshCache:', refreshCache);
      if (this.cacheFunc && !refreshCache) {
        // console.log('inside refresh');
        cacheData = (await this.cacheFunc.getItem()) || {
          items: [],
          count: 0,
          nextToken: null,
        };
        console.log(cacheData);

        const total = Math.floor(cacheData.items.length / limit);

        console.log('total:', total);
        console.log('page:', page);
        console.log(
          (!(page > total) || !cacheData.nextToken) &&
            cacheData.items.length > 0,
        );

        if (
          (!(page > total) || !cacheData.nextToken) &&
          cacheData.items.length > 0
        ) {
          const startIndex = (page - 1) * limit;
          const endIndex = startIndex + limit;

          return [...cacheData.items].slice(startIndex, endIndex);
        }
        // console.log('goNextToken:', goNextToken);
        // console.log('cache_length:', cacheData.items.length);
      } else {
        cacheData = {
          items: [],
          count: 0,
          nextToken: null,
        };
      }

      const authMode = navigation ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY';
      const response: any = await API.graphql({
        query: this.listFunc,
        variables: {
          ...this.filter,
          limit,
          nextToken: cacheData.nextToken,
        },
        authMode,
      });
      let itemCount = 0;
      if (navigation) {
        const countResponse: any = await API.graphql({
          query: getTableCount,
          variables: {
            ...this.filter,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        const jsonCount = JSON.parse(countResponse.data.getTableCount);
        itemCount = jsonCount.response;
      }

      const items = this.findItems(response.data);

      const nextToken = this.findNextToken(response.data);

      if (this.cacheFunc) {
        await this.cacheFunc.setItem({
          items: [...cacheData.items, ...items],
          count: itemCount,
          nextToken,
        });
        const total = Math.floor(cacheData.items.length / limit) + 1;

        console.log('total:', total);

        if (page > total && nextToken) {
          return await this.getList(navigation, refreshCache, page, limit);
        }
      }

      return [...items];
    } catch (error) {
      this.handleError(error);
      return [];
    }
  }

  async get(navigation: any, id: any): Promise<any | undefined> {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      if (navigation) {
        await UserSession(navigation);
      }
      const apiData: any = await API.graphql(
        graphqlOperation(this.getFunc, {id}),
      );
      toast.dismiss();
      return apiData.data[this.getName];
    } catch (error: any) {
      this.handleError(error);
      if (navigation) {
        navigation(this.route);
      }
    }
    return undefined;
  }

  async custom_call(
    navigation: any,
    func: any,
    filter: any,
    funcName: string,
  ): Promise<any | undefined> {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      if (navigation) {
        await UserSession(navigation);
      }
      const apiData: any = await API.graphql({
        query: func,
        variables: filter,
        authMode: 'API_KEY',
      });
      toast.dismiss();
      return apiData.data[funcName];
    } catch (error: any) {
      this.handleError(error);
      if (navigation) {
        navigation(this.route);
      }
    }
    return undefined;
  }

  async delete(navigation: any, id: any): Promise<boolean> {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      if (navigation) {
        await UserSession(navigation);
      }
      const input = {id};
      let authMode: any = {authMode: 'AMAZON_COGNITO_USER_POOLS'};
      if (!navigation) {
        authMode = {};
      }
      await API.graphql({
        query: this.deleteFunc,
        variables: {input},
        ...authMode,
      });
      await this.cacheFunc.removeItem();
      await FileSQLAPI.update({
        type: 'delete',
        table: this.backupTable,
        data: {id},
      });
      toast.dismiss();
      toast.success(this.deleteMSG);
      return true;
    } catch (error: any) {
      this.handleError(error);
    }
    return false;
  }

  async save(
    navigation: any,
    input: any,
    doNotShowMessage?: true,
  ): Promise<void> {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      if (navigation) {
        const user: any = await UserSession(navigation);
        input.createdBy = user.username;
        input.updatedBy = user.username;
      }
      if (this.key) {
        input.key = this.key;
      }
      input.createdAt = new Date().toISOString();
      input.updatedAt = new Date().toISOString();
      let authMode: any = {authMode: 'AMAZON_COGNITO_USER_POOLS'};
      if (!navigation) {
        authMode = {};
      }
      const response = (await API.graphql({
        query: this.insertFunc,
        variables: {input},
        ...authMode,
      })) as any;
      await FileSQLAPI.update({
        type: 'insert',
        table: this.backupTable,
        id: response.data[this.insertFunc],
      });
      if (this.cacheFunc) {
        await this.cacheFunc.removeItem();
      }
      toast.dismiss();
      if (!doNotShowMessage) {
        toast.success(this.insertMSG);
      }
      if (navigation) {
        navigation(this.route);
      }
    } catch (error: any) {
      this.handleError(error, input);
    }
  }

  async update(navigation: any, data: any, updateInput: any): Promise<void> {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      if (navigation) {
        const user: any = await UserSession(navigation);
        data.updatedBy = user.username;
      }
      Object.keys(data).forEach(key => {
        if (updateInput[key]) {
          data[key] = updateInput[key];
        }
      });
      delete data.__typename;
      if (this.key) {
        data.key = this.key;
      }
      data.updatedAt = new Date().toISOString();
      const input = data;
      let authMode: any = {authMode: 'AMAZON_COGNITO_USER_POOLS'};
      if (!navigation) {
        authMode = {};
      }
      await API.graphql({
        query: this.updateFunc,
        variables: {input},
        ...authMode,
      });
      await FileSQLAPI.update({
        type: 'update',
        table: this.backupTable,
        data: {updateInput},
      });
      if (this.cacheFunc) {
        await this.cacheFunc.removeItem();
      }
      if (navigation) {
        navigation(this.route);
      }
      toast.dismiss();
      toast.success(this.updateMSG);
    } catch (error: any) {
      this.handleError(error, updateInput);
    }
  }
}

export default UtilFuncAPIs;
