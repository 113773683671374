import Pages from './Pages';
import FormPage from './FormPage';

const CMSPagesRoutes = {
  cmsPages: {
    Component: Pages,
    type: 'private',
    route: '/members/cms/pages',
    title: 'Pages',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'CMS'}],
  },
  cmsNewPage: {
    Component: FormPage,
    type: 'private',
    route: '/members/cms/pages/add',
    title: 'New Page',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Pages', route: '/members/cms/pages'},
    ],
  },
  cmsUpdatePage: {
    Component: FormPage,
    type: 'private',
    route: '/members/cms/pages/update/:id',
    title: 'Update Page',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Pages', route: '/members/cms/pages'},
    ],
  },
};

export default CMSPagesRoutes;
