import {
  getSurveyDownloadReportEmail,
  getSortedSurveyDownloadReportEmail,
} from './amplify/graphql/queries';

import {
  createSurveyDownloadReportEmail,
  deleteSurveyDownloadReportEmail,
  updateSurveyDownloadReportEmail,
} from './amplify/graphql/mutations';
import SurveyDownloadReportEmailCache from '@internal/utils/cache/SurveyDownloadReportEmailCache';

import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const SurveyDownloadReportEmailAPI = new UtilFuncAPIs({
  ref: 'SurveyDownloadReportEmailAPI',
  cacheFunc: SurveyDownloadReportEmailCache,
  filter: {
    key: 'Survey_Download_Report_Email',
    sortDirection: 'DESC',
  },
  listFunc: getSortedSurveyDownloadReportEmail,
  getFunc: getSurveyDownloadReportEmail,
  route: '/members/records/reports-downloaders',
  insertFunc: createSurveyDownloadReportEmail,
  updateFunc: updateSurveyDownloadReportEmail,
  deleteFunc: deleteSurveyDownloadReportEmail,
  backupTable: '',
  deleteMSG: 'Downloader deleted',
  insertMSG: 'Downloader saved',
  updateMSG: 'Downloader updated',
  key: 'Survey_Download_Report_Email',
  getName: 'getSurveyDownloadReportEmail',
});

export default SurveyDownloadReportEmailAPI;
