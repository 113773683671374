import AsyncStorage from './AsyncStorage';
import type CMSSinglePageFormTypes from '@internal/types/CMSConfigurationsTypes';

const CacheKey = 'CMSConfigurationCache';

const CMSConfigurationsCache = {
  setItem: async (value: CMSSinglePageFormTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<CMSSinglePageFormTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as CMSSinglePageFormTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default CMSConfigurationsCache;
