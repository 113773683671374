import {
  getSortedSurveyReports,
  getSurveyNotifyReportEmail,
} from './amplify/graphql/queries';

import {
  createSurveyNotifyReportEmail,
  deleteSurveyNotifyReportEmail,
  updateSurveyNotifyReportEmail,
} from './amplify/graphql/mutations';
import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';
import SurveyDownloadReportEmailCahce from '@internal/utils/cache/SurveyDownloadReportEmailCahce';

const SurveyNotifyReportEmailAPI = new UtilFuncAPIs({
  ref: 'SurveyNotifyReportEmail',
  cacheFunc: SurveyDownloadReportEmailCahce,
  filter: {
    key: 'Survey_Notify_Report_Email',
    sortDirection: 'ASC',
  },
  listFunc: getSortedSurveyReports,
  getFunc: getSurveyNotifyReportEmail,
  route: '/members/survey/reports/downloaders',
  insertFunc: createSurveyNotifyReportEmail,
  updateFunc: updateSurveyNotifyReportEmail,
  deleteFunc: deleteSurveyNotifyReportEmail,
  backupTable: 'cms_survey_settings',
  deleteMSG: 'Subscriber deleted',
  insertMSG: 'Subscriber saved',
  updateMSG: 'Subscriber updated',
  key: 'Survey_Notify_Report_Email',
  getName: 'getSurveyNotifyReportEmail',
});

export default SurveyNotifyReportEmailAPI;
