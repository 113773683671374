import React, {useState} from 'react';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from 'styled-components';
import {type TextFieldProps} from '@mui/material';
import {type IconsMap} from '@internal/constants';

type CurvedFileElementProps = TextFieldProps & {
  label: string;
  errorInfo?: {hasError: boolean; errorMsg: string};
  onChangeInput: (value: any, name: string) => void;
  name: string;
  icon?: keyof typeof IconsMap;
  isRequired?: boolean;
  fileType?: string;
  value?: any;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CurvedFileElement: React.FC<CurvedFileElementProps> = ({
  label,
  onChangeInput,
  name,
  icon,
  errorInfo,
  isRequired,
  fileType,
  value,
  ...props
}) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [filename, setFilename] = useState<any>();
  const isError = errorInfo?.hasError;

  React.useEffect(() => {
    if (value && !filename) {
      const fileValue = JSON.parse(value);

      if (fileValue.file.includes('image/')) {
        setImagePreview(fileValue.file);
      }
      setFilename(fileValue);
      onChangeInput(fileValue, name);
    }
  }, [value]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFilename(file);
      onChangeInput(file, name);

      if (file.type.startsWith('image/')) {
        setImagePreview(URL.createObjectURL(file));
      }
    }
  };

  const renderButtonContent = () => {
    const elementLabel = isRequired ? label + '*' : label;

    return isError ? (
      <span style={{color: 'red'}}>
        {elementLabel + ' (' + errorInfo?.errorMsg + ')'}
      </span>
    ) : filename ? (
      <span style={{color: 'green'}}>
        {elementLabel + ' (' + filename?.name + ')'}
      </span>
    ) : (
      elementLabel
    );
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: '#FF9800',
          color: '#fff',
          marginTop: '16px',
          marginBottom: '8px',
        }}
        component="label"
        variant="contained"
        startIcon={
          imagePreview ? (
            <img
              src={imagePreview}
              alt="preview"
              style={{width: '24px', height: '24px'}}
            />
          ) : (
            <CloudUploadIcon />
          )
        }
        fullWidth // <-- This makes the button width 100%
      >
        {renderButtonContent()}
        <VisuallyHiddenInput
          type="file"
          onChange={handleFileChange}
          accept={fileType}
        />
      </Button>
    </>
  );
};

export default CurvedFileElement;
