import {API, graphqlOperation} from 'aws-amplify';
import {getByMenuIDForCMSMenuItem} from './amplify/graphql/queries';
import {
  createCMSMenuItems,
  deleteCMSMenuItems,
  updateCMSMenuItems,
} from './amplify/graphql/mutations';
import {toast} from 'react-toastify';
import UserSession from './UserSessions';
import type CMSMenuItemsTypes from '@internal/types/CMSMenuItemsTypes';
import FileSQLAPI from '@internal/apis/SQLBackup';

const CMSMenusRoute = '/members/cms/menus';
const table = 'CMSMenuItems';

const CMSMenuItemsAPI = {
  getByMenuID: async (
    navigation: any,
    menuID: string,
  ): Promise<CMSMenuItemsTypes[] | undefined> => {
    try {
      if (navigation) {
        await UserSession(navigation);
      }


      const result1: any = await API.graphql({
        query: getByMenuIDForCMSMenuItem,
        variables: {menuID},
        authMode: 'API_KEY',
      });
      return result1.data.getByMenuIDForCMSMenuItem.items;
    } catch (ex) {
      console.log(ex);
      toast.error('Unable to contact server');
      if (navigation) {
        navigation(CMSMenusRoute);
      }
    }
    return undefined;
  },
  delete: async (navigation: any, id: string): Promise<boolean> => {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      await UserSession(navigation);
      const input = {id};
      await API.graphql({
        query: deleteCMSMenuItems,
        variables: {input},
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      await FileSQLAPI.update({type: 'delete', table, data: {id}});
      toast.dismiss();
      return true;
    } catch (error: any) {
      toast.dismiss();
      if ((error + '').includes('Network error')) {
        toast.error('Unable to connect to internet', {autoClose: false});
      } else {
        console.log(error);
        toast.error('Unable to contact server');
      }
    }
    return false;
  },
  save: async (navigation: any, input: CMSMenuItemsTypes): Promise<void> => {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      const user: any = await UserSession(navigation);
      input.key = "CMS_Menu_Items";
      input.createdBy = user.username;
      input.updatedBy = user.username;
      input.createdAt = new Date().toISOString();
      input.updatedAt = new Date().toISOString();
      const response = (await API.graphql({
        query: createCMSMenuItems,
        variables: {input},
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as any;
      await FileSQLAPI.update({
        type: 'insert',
        table,
        id: response.data.createCMSPages,
      });
      toast.dismiss();
    } catch (error: any) {
      toast.dismiss();
      if ((error + '').includes('Network error')) {
        toast.error('Unable to connect to internet', {autoClose: false});
      } else {
        console.log(error);
        toast.error('Unable to contact server');
      }
    }
  },
  update: async (navigation: any, updateInput: any): Promise<void> => {
    try {
      toast.dismiss();
      toast.loading('Processing...');
      const user: any = await UserSession(navigation);
      delete updateInput.__typename;
      updateInput.updatedBy = user.username;
      updateInput.updatedAt = new Date().toISOString();
      const input = updateInput;
      await API.graphql({
        query: updateCMSMenuItems,
        variables: {input},
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      await FileSQLAPI.update({type: 'update', table, data: {updateInput}});
      toast.dismiss();
    } catch (error: any) {
      toast.dismiss();
      if ((error + '').includes('Network error')) {
        toast.error('Unable to connect to internet', {autoClose: false});
      } else {
        console.log(error);
        toast.error('Unable to contact server');
      }
    }
  },
};

export default CMSMenuItemsAPI;
