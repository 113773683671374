import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import {FormGrid} from '@internal/components';
import CMSPagesAPI from '@internal/apis/CMSPagesAPI';
import {
  getByTitleCMSPages,
  getByURLCMSPages,
} from '@internal/apis/amplify/graphql/queries';
import {toast} from 'react-toastify';

const FormPage: React.FC<BasePageProps> = ({navigatePage, urlParams}) => {
  const [loading, setLoading] = React.useState(false);
  const [key, setKey] = React.useState(Date.now());
  const formConfig = React.useRef<any>([
    {
      name: 'pageTitle',
      label: 'Title',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Title can't be blank.",
        },
      },
    },
    {
      name: 'pageURL',
      label: 'URL',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "URL can't be blank.",
        },
      },
    },
    {
      name: 'metaDescription',
      label: 'Meta Description',
      fieldType: 'input',
      isRequired: false,
    },
    {
      name: 'content',
      label: 'Page Content',
      fieldType: 'editor',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Page content can't be blank.",
        },
      },
    },
  ]);
  const record = React.useRef<any>();

  React.useEffect(() => {
    if (urlParams.id) {
      void reloadFunc().then();
    }
  }, []);
  const reloadFunc = async (): Promise<void> => {
    setLoading(true);
    record.current = await CMSPagesAPI.get(navigatePage, urlParams.id ?? '');
    for (let i = 0; i < formConfig.current.length; i++) {
      formConfig.current[i].value = record.current[formConfig.current[i].name];
    }
    setLoading(false);
    setKey(Date.now());
  };
  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    const titleInfo = await CMSPagesAPI.custom_call(
      navigatePage,
      getByTitleCMSPages,
      {
        pageTitle: data.pageTitle,
      },
      'getByTitleCMSPages',
    );

    const urlInfo = await CMSPagesAPI.custom_call(
      navigatePage,
      getByURLCMSPages,
      {
        pageURL: data.pageURL,
      },
      'getByURLCMSPages',
    );
    if (urlParams.id) {
      if (
        titleInfo.items.length > 0 &&
        titleInfo.items[0].id !== record.current.id
      ) {
        toast.warning('Title already exists');
        setLoading(false);
        return;
      } else if (
        urlInfo.items.length > 0 &&
        urlInfo.items[0].id !== record.current.id
      ) {
        toast.warning('Url already exists');
        setLoading(false);
        return;
      }
      await CMSPagesAPI.update(navigatePage, record.current, data);
    } else {
      if (titleInfo.items.length > 0) {
        toast.warning('Title already exists');
        setLoading(false);
        return;
      } else if (urlInfo.items.length > 0) {
        toast.warning('Url already exists');
        setLoading(false);
        return;
      }
      await CMSPagesAPI.save(navigatePage, data);
    }
    setLoading(false);
  };
  return (
    <React.Fragment key={key}>
      <FormGrid
        fields={formConfig.current ?? []}
        onSubmit={handleFormSubmit}
        submitButtonText={urlParams.id ? 'Update' : 'Save'}
        doShowCardHeader
        reloadFunc={urlParams.id ? reloadFunc : undefined}
        title={urlParams.id ? 'Update Page' : 'New Page'}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default FormPage;
