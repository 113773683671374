import React from 'react';
import GoogleEmailCaptchaDownload from '@internal/apis/GoogleCaptchaEmailDownload';

const DashbaordPage: React.FC = () => {
  React.useEffect(() => {
    GoogleEmailCaptchaDownload();
  }, []);
  return <div>dd</div>;
};

export default DashbaordPage;
