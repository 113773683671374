import React, {useState} from 'react';
import type DataGridProps from './DataGridProps';
import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  TextField,
  keyframes,
} from '@mui/material';
import {StyledGridCard} from '@internal/styles';

import SearchIcon from '@mui/icons-material/Search';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import MUIPagination from '@mui/material/Pagination';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const DataGrid: React.FC<DataGridProps> = ({
  actionID,
  itemProps,
  items,
  itemActions,
  doShowCardHeader,
  doShowTableHeader,
  sm,
  loadRecords,
  addInfo,
}) => {
  const [isSearchVisible, setSearchVisibility] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);

  React.useEffect(() => {
    void pagination(1, '', false).then();
  }, []);

  const HeaderSkeleton = () => (
    <Grid container spacing={2} style={{marginBottom: '16px'}}>
      {itemProps.map((_, index) => (
        <Grid key={index} item xs={12} sm={sm}>
          <Skeleton variant="text" width="80%" height={25} />
        </Grid>
      ))}
      {itemActions && (
        <Grid item xs={12} sm={sm}>
          <Skeleton variant="text" width="80%" height={25} />
        </Grid>
      )}
    </Grid>
  );

  const ItemSkeleton = () => (
    <Grid container spacing={2} style={{marginBottom: '8px', marginTop: '4px'}}>
      {itemProps.map((_, index) => (
        <Grid item xs={12} sm={sm} key={index} style={{alignItems: 'center'}}>
          <Skeleton variant="text" width="80%" height={25} />
        </Grid>
      ))}
      {itemActions && (
        <Grid item xs={12} sm={sm} style={{alignItems: 'right'}}>
          <Skeleton variant="text" width="80%" height={25} />
        </Grid>
      )}
    </Grid>
  );

  const Header = () => (
    <Grid
      container
      spacing={2}
      style={{marginBottom: '16px', alignItems: 'center'}}>
      {itemProps.map((itemInfo, index) => (
        <Grid key={index} item xs={12} sm={sm}>
          <Typography
            variant="subtitle1"
            component="div"
            fontWeight="bold"
            style={{textAlign: 'left'}}>
            {itemInfo.text ? <>{itemInfo.text}</> : <></>}
          </Typography>
        </Grid>
      ))}
      {itemActions && (
        <Grid item xs={12} sm={sm} style={{textAlign: 'right'}}>
          &nbsp;
        </Grid>
      )}
    </Grid>
  );

  const ItemRender = (props: any) => (
    <Grid container spacing={2} style={{alignItems: 'center'}}>
      {itemProps.map((itemInfo, index) => (
        <Grid item xs={12} sm={sm} key={index}>
          {itemInfo.text ? <>{props.item[itemInfo.key]}</> : <></>}
        </Grid>
      ))}
      {itemActions && (
        <Grid item xs={12} sm={sm} style={{textAlign: 'right'}}>
          {itemActions.map((action, index) => (
            <IconButton
              key={index}
              onClick={async () => {
                await action.handler(props.item[actionID]);
              }}>
              {action.icon}
            </IconButton>
          ))}
        </Grid>
      )}
    </Grid>
  );

  const pagination = async (
    value: any,
    name: string,
    refreshRecord: boolean = false,
  ) => {
    setIsRotating(true);
    const number = await loadRecords(refreshRecord, value);
    setPages(number);
    setPage(value);
    setIsRotating(false);
  };
  const MyCardHeader = () => {
    return (
      <CardHeader
        avatar={
          <>
            {addInfo && (
              <Box sx={{display: 'flex', alignItems: 'start'}}>
                <Button
                  onClick={addInfo?.handler}
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    fontSize: '0.875rem', // Default font-size for Material-UI's small buttons
                  }}>
                  <AddIcon />
                  {addInfo?.text}
                </Button>
              </Box>
            )}
            <Box sx={{display: 'flex', alignItems: 'start'}}></Box>
          </>
        }
        action={
          <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {isSearchVisible && !isRotating ? (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  sx={{
                    fontSize: '0.875rem',
                    '& .MuiOutlinedInput-root': {
                      height: '2.1rem',
                      padding: '0px',
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '0.5rem 1rem',
                    },
                  }}
                />
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisibility(prev => !prev);
                  }}>
                  <SearchIcon />
                </IconButton>
              )}

              <IconButton
                onClick={async () => {
                  await pagination(1, '', true);
                }}
                sx={{
                  animation: isRotating
                    ? `${rotateAnimation} 1s linear infinite`
                    : 'none',
                }}>
                <RefreshIcon />
              </IconButton>
            </Box>
          </>
        }
      />
    );
  };

  const ItemFragment = () => {
    if (items && items.length < 1) {
      return <></>;
    }
    return items.map((item: any, index) => (
      <React.Fragment key={item[actionID]}>
        <ItemRender item={item} key={index} />
        {index !== items.length - 1 && <Divider key={index + 'divider'} />}
      </React.Fragment>
    ));
  };
  const PaginationHeader = () => {
    return (
      <Grid container spacing={2} style={{alignItems: 'center'}}>
        <Grid item xs={12} sm={6}>
          <></>
        </Grid>
        <Grid item xs={12} sm={6} style={{textAlign: 'right'}}>
          Pages:
          <MUIPagination
            count={Math.ceil(pages / 25)}
            page={page}
            onChange={async (e: any, page: number) => {
              await pagination(page, '');
            }}
            sx={{float: 'right'}}
            color={'primary'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <StyledGridCard variant="outlined">
      {doShowCardHeader && <MyCardHeader />}
      <CardContent>
        <PaginationHeader />
        {doShowTableHeader && (isRotating ? <HeaderSkeleton /> : <Header />)}
        <Divider />
        {isRotating && items.length === 0 ? (
          // If loading and items is empty, render 10 skeletons
          Array.from({length: 5}).map((_, index) => (
            <React.Fragment key={`skeleton-${index}`}>
              <ItemSkeleton />
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <ItemFragment />
        )}
      </CardContent>
    </StyledGridCard>
  );
};

export default DataGrid;
