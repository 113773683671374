import React, {useState} from 'react';
import {InputAdornment} from '@mui/material';
import {CurvedTextField as CurvedTextFieldStyled} from '@internal/styles';
import type CurvedTextElementProps from './CurvedTextElementProps';
import {IconsMap} from '@internal/constants';
const CurvedTextElement: React.FC<CurvedTextElementProps> = ({
  label,
  errorInfo,
  inputType,
  isRequired,
  icon,
  onChangeInput,
  onChange,
  name,
  ...props
}) => {
  const [value, setValue] = useState('');
  const isError = errorInfo?.hasError;
  let inputProps = {};
  if (icon) {
    const IconComponent = IconsMap[icon];
    inputProps = {
      startAdornment: (
        <InputAdornment position="start">
          <IconComponent color={isError ? 'error' : 'action'} />
        </InputAdornment>
      ),
    };
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChangeInput(e.target.value, name);
  };
  const elementLabel = isRequired ? label + '*' : label;
  return (
    <CurvedTextFieldStyled
      label={
        isError ? elementLabel + ' (' + errorInfo?.errorMsg + ')' : elementLabel
      }
      error={isError}
      variant="outlined"
      margin="normal"
      fullWidth
      type={inputType}
      value={value}
      onChange={handleInputChange}
      InputProps={inputProps}
      {...props}
    />
  );
};

export default CurvedTextElement;
