const AsyncStorage = {
  setItem: async (key: string, value: string): Promise<string> => {
    return await new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve(value);
      } catch (error) {
        reject(error);
      }
    });
  },

  getItem: async (key: string): Promise<string | null> => {
    return await new Promise((resolve, reject) => {
      try {
        const value = localStorage.getItem(key);
        resolve(value);
      } catch (error) {
        reject(error);
      }
    });
  },

  removeItem: async (key: string): Promise<boolean> => {
    return await new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(key);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },

  clear: async (): Promise<boolean> => {
    return await new Promise((resolve, reject) => {
      try {
        localStorage.clear();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default AsyncStorage;
