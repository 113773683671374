import {styled} from 'styled-components';
const HeaderLink = styled.a`
  color: #406ab5;
  text-decoration: none;
  transition: color 0.15s ease;
  background-color: transparent;
  :focus {
    outline: 5px solid rgba(43, 100, 147, 0.2);
    outline-offset: 0;
  }
  :hover {
    color: #305088;
    text-decoration: underline;
    cursor: pointer;
  }
`;
export default HeaderLink;
