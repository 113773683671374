import Metas from './Metas';
import FormMeta from './FormMeta';

const CMSMetasRoutes = {
  cmsMetas: {
    Component: Metas,
    type: 'private',
    route: '/members/cms/metas',
    title: 'Metas',
    breadcrumb: [{title: 'Home', route: '/'}, {title: 'CMS'}],
  },
  cmsNewMetas: {
    Component: FormMeta,
    type: 'private',
    route: '/members/cms/metas/add',
    title: 'New Meta',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Metas', route: '/members/cms/metas'},
    ],
  },
  cmsUpdateMetas: {
    Component: FormMeta,
    type: 'private',
    route: '/members/cms/metas/update/:id',
    title: 'Update Meta',
    breadcrumb: [
      {title: 'Home', route: '/'},
      {title: 'CMS'},
      {title: 'Metas', route: '/members/cms/metas'},
    ],
  },
};

export default CMSMetasRoutes;
