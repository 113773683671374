import {
  getSystemInternalConfigurationVariablesReadonly,
  listSystemInternalConfigurationVariablesReadonlies,
} from './amplify/graphql/queries';

import {
  createSystemInternalConfigurationVariablesReadonly,
  deleteSystemInternalConfigurationVariablesReadonly,
  updateSystemInternalConfigurationVariablesReadonly,
} from './amplify/graphql/mutations';
import SystemInternalConfigurationVariablesReadonlyCache from '@internal/utils/cache/SystemInternalConfigurationVariablesReadonlyCache';

import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const SystemInternalConfigurationVariablesReadonlyAPI = new UtilFuncAPIs({
  ref: 'SystemInternalConfigurationVariablesReadonlyAPI',
  cacheFunc: SystemInternalConfigurationVariablesReadonlyCache,
  filter: {
    key: '',
  },
  listFunc: listSystemInternalConfigurationVariablesReadonlies,
  getFunc: getSystemInternalConfigurationVariablesReadonly,
  route: '/members/system/internal-versions',
  insertFunc: createSystemInternalConfigurationVariablesReadonly,
  updateFunc: updateSystemInternalConfigurationVariablesReadonly,
  deleteFunc: deleteSystemInternalConfigurationVariablesReadonly,
  backupTable: '',
  deleteMSG: ' deleted',
  insertMSG: ' saved',
  updateMSG: ' updated',
  key: '',
  getName: 'getSystemInternalConfigurationVariablesReadonly',
});

export default SystemInternalConfigurationVariablesReadonlyAPI;
