import {styled} from 'styled-components';
import Typography from '@mui/material/Typography';

const StyledTypography = styled(Typography)`
  &.MuiTypography-h3 {
    clear: both;
    font-size: 36px;
    font-weight: 600;
    color: #2e374b;
    text-align: center;
    line-height: 1.2;
    margin: 0 0 2.5rem;
  }
`;
export default StyledTypography;
