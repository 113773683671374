import AsyncStorage from './AsyncStorage';
import type SystemGlobalSecretsTypes from '@internal/types/SystemGlobalSecretsTypes';

const CacheKey = 'SystemGlobalSecrets';

const SystemGlobalSecretsCache = {
  setItem: async (value: SystemGlobalSecretsTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<SystemGlobalSecretsTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as SystemGlobalSecretsTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default SystemGlobalSecretsCache;
