import AsyncStorage from './AsyncStorage';
import type CMSPagesTypes from '@internal/types/CMSPagesTypes';

const CacheKey = 'CMSPagesCache';

const CMSPagesCache = {
  setItem: async (value: CMSPagesTypes): Promise<string> => {
    return await AsyncStorage.setItem(CacheKey, JSON.stringify(value));
  },

  getItem: async (): Promise<CMSPagesTypes[] | null> => {
    const items = await AsyncStorage.getItem(CacheKey);
    return items ? (JSON.parse(items) as CMSPagesTypes[]) : null;
  },

  removeItem: async (): Promise<boolean> => {
    return await AsyncStorage.removeItem(CacheKey);
  },
};

export default CMSPagesCache;
