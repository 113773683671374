import React from 'react';
import {DataGrid} from '@internal/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SurveyReportsAPI from '@internal/apis/SurveyReportsAPI';
import type SurveyReportsTypes from '@internal/types/SurveyReportsTypes';
import type BasePageProps from '@internal/pages/BasePageProps';

const Reports: React.FC<BasePageProps> = ({navigatePage}) => {
  const [record, setRecord] = React.useState<SurveyReportsTypes[]>([]);
  const loadRecords = async (
    refreshCache: boolean,
    page: number,
  ): Promise<number> => {
    setRecord(await SurveyReportsAPI.getList(navigatePage, refreshCache, page));
    return await SurveyReportsAPI.getPageNumbers();
  };
  return (
    <DataGrid
      sm={3}
      itemProps={[
        {type: 'data', key: 'year', text: 'Year'},
        {type: 'data', key: 'reportStatus', text: 'Status'},
        {type: 'data', key: 'description', text: 'Description'},
      ]}
      items={record}
      itemActions={[
        {
          icon: <EditIcon color="primary" />,
          handler: async (id: any) => {
            navigatePage('/members/survey/reports/update/' + id);
          },
        },
        {
          icon: <DeleteIcon color="primary" />,
          handler: async (id: any) => {
            if (await SurveyReportsAPI.delete(navigatePage, id)) {
              await loadRecords(false, 1);
            }
          },
        },
      ]}
      addInfo={{
        text: 'Report',
        handler: () => {
          navigatePage('add');
        },
      }}
      actionID={'id'}
      loadRecords={loadRecords}
      doShowCardHeader
      doShowTableHeader
    />
  );
};

export default Reports;
