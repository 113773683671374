import React from 'react';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Page not found.</p>
    </div>
  );
};
export default NotFoundPage;
