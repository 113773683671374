import {getSurveySettings, listSurveySettings} from './amplify/graphql/queries';

import {
  createSurveySettings,
  deleteSurveySettings,
  updateSurveySettings,
} from './amplify/graphql/mutations';
import SurveySettingsCache from '@internal/utils/cache/SurveySettingsCache';

import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const SurveySettingsAPI = new UtilFuncAPIs({
  ref: 'SurveySettingsAPI',
  cacheFunc: SurveySettingsCache,
  filter: {
    key: 'Survey_Settings',
  },
  listFunc: listSurveySettings,
  getFunc: getSurveySettings,
  route: '/members/survey/settings',
  insertFunc: createSurveySettings,
  updateFunc: updateSurveySettings,
  deleteFunc: deleteSurveySettings,
  backupTable: 'survey_settings',
  deleteMSG: 'Settings deleted',
  insertMSG: 'Settings saved',
  updateMSG: 'Settings updated',
  key: 'Survey_Settings',
  getName: 'getSurveySettings',
});

export default SurveySettingsAPI;
