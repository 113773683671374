import React from 'react';
import {DataGrid} from '@internal/components';
import SurveyDownloadReportEmailAPI from '@internal/apis/SurveyDownloadReportEmailAPI';
import type SurveyDownloadReportEmailTypes from '@internal/types/SurveyDownloadReportEmailTypes';
import type BasePageProps from '@internal/pages/BasePageProps';

const ReportsDownloaders: React.FC<BasePageProps> = ({navigatePage}) => {
  const [record, setRecord] = React.useState<SurveyDownloadReportEmailTypes[]>(
    [],
  );
  const loadRecords = async (
    refreshCache: boolean,
    page: number,
  ): Promise<number> => {
    setRecord(
      await SurveyDownloadReportEmailAPI.getList(
        navigatePage,
        refreshCache,
        page,
      ),
    );
    return await SurveyDownloadReportEmailAPI.getPageNumbers();
  };

  return (
    <DataGrid
      sm={6}
      itemProps={[
        {type: 'data', key: 'reportYear', text: 'Year'},
        {type: 'data', key: 'email', text: 'Downloader'},
      ]}
      items={record}
      addInfo={{
        text: 'Reports Downloaders',
        handler: () => {},
      }}
      actionID={'id'}
      loadRecords={loadRecords}
      doShowCardHeader
      doShowTableHeader
    />
  );
};

export default ReportsDownloaders;
