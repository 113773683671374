const GAEvents = {
  setSurveyResumption(email: string) {
    const gtag = (window as any).gtag;
    if (gtag) {
      gtag('config', 'G-NCDYXM0456', {
        page_title: '/login',
      });
      gtag('event', 'survey_resumption', {
        email,
      });
    }
  },
  setPage(page: string) {
    const gtag = (window as any).gtag;
    if (gtag) {
      gtag('config', 'G-NCDYXM0456', {
        page_title: page,
      });

      const urlParams = new URLSearchParams(window.location.search);
      const fromParam = urlParams.get('from');
      if (fromParam) {
        gtag('event', 'partnerID', {
          partnerID: fromParam,
        });
      }
    }
  },
  pageview() {
    const gtag = (window as any).gtag;
    if (gtag) {
      gtag('config', 'G-NCDYXM0456', {
        send_page_view: true,
      });
    }
  },
  logNewCriteriaSet() {
    GAEvents.send({
      hitType: 'event',
      eventCategory: 'criteriaSet',
      eventAction: 'new',
    });
  },
  logReportDownload(reportId: string) {
    GAEvents.send({
      hitType: 'event',
      eventCategory: 'reportDownload',
      eventAction: reportId,
    });
  },
  send(payload: any) {
    const gtag = (window as any).gtag;
    if (gtag) {
      gtag(payload.hitType, payload.eventAction, {
        event_category: payload.eventCategory,
      });
    }
  },
};
export default GAEvents;
