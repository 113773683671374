import styled from 'styled-components';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export const LogoBox = styled(Box)`
  background-color: #367fbb;
  margin: 5px !important;
`;

export const StyledListItem = styled(ListItem)`
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const StyledListItemButton = styled(ListItemButton)`
  border-radius: 6px;
  margin-top: 5px !important;
`;
export const StyledListItemButtonText = styled(ListItemButton)`
  border-radius: 6px;
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
`;

export const StyledListItemText = styled(ListItem)`
  margin-top: 10px !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
`;

export const StyledListItemTextNoMenu = styled(ListItemText)`
  font-size: 14px !important;
  margin: 0 !important;
  padding: 0 !important;
  && .MuiListItemText-primary {
    font-size: 14px; // adjust as needed
  }
`;
