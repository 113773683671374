{
  "name": "startupmuster-admin-fe-v2",
  "version": "1.4.2",
  "private": true,
  "dependencies": {
    "@aws-amplify/auth": "^5.6.4",
    "@aws-amplify/ui-react": "^5.3.1",
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/icons-material": "^5.14.8",
    "@mui/material": "^5.14.8",
    "@parcel/transformer-css": "^2.9.3",
    "@parcel/transformer-sass": "^2.9.3",
    "@types/node": "^16.7.13",
    "@types/react": "^18.2.21",
    "@types/react-dom": "^18.2.7",
    "@types/react-window": "^1.8.8",
    "aws-amplify": "^5.3.10",
    "caniuse-lite": "^1.0.30001538",
    "cross-env": "^7.0.3",
    "dompurify": "^3.0.6",
    "quill": "^1.3.7",
    "react": "^18.2.0",
    "react-dnd": "^16.0.1",
    "react-dnd-html5-backend": "^16.0.1",
    "react-dom": "^18.2.0",
    "react-gesture-responder": "^2.1.0",
    "react-google-recaptcha-v3": "^1.10.1",
    "react-grid-dnd": "^2.1.2",
    "react-helmet": "^6.1.0",
    "react-quill": "^2.0.0",
    "react-router-dom": "^6.15.0",
    "react-scripts": "5.0.1",
    "react-toastify": "^9.1.3",
    "react-virtualized-auto-sizer": "^1.0.20",
    "react-window": "^1.8.9",
    "sass": "^1.67.0",
    "styled-components": "^6.0.7",
    "typescript": "^5.2.2",
    "uuid": "^9.0.1",
    "web-vitals": "^2.1.0"
  },
  "scripts": {
    "start-fe": "cross-env REACT_APP_System=ui rm -rf ./.parcel-cache && cross-env REACT_APP_System=ui yarn parcel serve public/index.html --port 1234  --dist-dir ./dist/fe & cross-env REACT_APP_System=ui browser-sync start --host 192.168.1.2 --proxy \"localhost:1234\" --files \"dist/fe/**/*.*\"",
    "start-admin": "cross-env REACT_APP_System=admin rm -rf ./.parcel-cache && cross-env REACT_APP_System=admin yarn parcel serve public/index.html  --port 1235 --dist-dir ./dist/admin & cross-env REACT_APP_System=admin browser-sync start --proxy \"localhost:1235\" --files \"dist/admin/**/*.*\"",
    "build-fe": "cross-env REACT_APP_System=ui rm -rf ./.parcel-cache && cross-env REACT_APP_System=ui yarn parcel build public/index.html  --dist-dir ./build/fe ",
    "build-admin": "cross-env REACT_APP_System=admin rm -rf ./.parcel-cache && cross-env REACT_APP_System=admin yarn parcel build public/index.html --dist-dir ./build/admin ",
    "server-fe": "browser-sync start --server build/fe --files \"build/fe/**/*.*\" --no-notify --port 3000",
    "server-admin": "browser-sync start --server build/admin --files \"build/admin/**/*.*\" --no-notify --port 3001",
    "build": "yarn parcel build public/index.html --dist-dir ./build",
    "test": "jest --colors",
    "eject": "react-scripts eject",
    "test-coverage": "yarn jest --clearCache;yarn test -- --coverage",
    "storybook": "storybook dev -p 6006",
    "build-storybook": "storybook build"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@babel/core": "^7.22.17",
    "@babel/preset-react": "^7.22.15",
    "@babel/preset-typescript": "^7.22.15",
    "@hot-loader/react-dom": "^17.0.2",
    "@parcel/packager-raw-url": "^2.9.3",
    "@parcel/transformer-typescript-tsc": "^2.9.3",
    "@parcel/transformer-webmanifest": "^2.9.3",
    "@storybook/addon-actions": "^7.4.1",
    "@storybook/addon-essentials": "^7.4.1",
    "@storybook/addon-interactions": "^7.4.1",
    "@storybook/addon-links": "^7.4.1",
    "@storybook/addon-onboarding": "^1.0.8",
    "@storybook/blocks": "^7.4.1",
    "@storybook/preset-create-react-app": "^7.4.1",
    "@storybook/react": "^7.4.1",
    "@storybook/react-webpack5": "^7.4.1",
    "@storybook/testing-library": "^0.2.0",
    "@testing-library/jest-dom": "^6.1.3",
    "@testing-library/react": "^14.0.0",
    "@testing-library/react-hooks": "^8.0.1",
    "@testing-library/user-event": "^14.4.3",
    "@types/jest": "^29.5.4",
    "@types/styled-components": "^5.1.27",
    "@typescript-eslint/eslint-plugin": "^6.6.0",
    "@typescript-eslint/parser": "^6.6.0",
    "babel-jest": "^29.6.4",
    "babel-loader": "^9.1.3",
    "babel-plugin-module-resolver": "^5.0.0",
    "babel-plugin-named-exports-order": "^0.0.2",
    "browser-sync": "^2.29.3",
    "buffer": "^5.5.0||^6.0.0",
    "customize-cra": "^1.0.0",
    "eslint": "^8.48.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-config-standard-with-typescript": "^39.0.0",
    "eslint-plugin-n": "^16.1.0",
    "eslint-plugin-prettier": "^5.0.0",
    "eslint-plugin-promise": "^6.1.1",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-security": "^1.7.1",
    "eslint-plugin-storybook": "^0.6.13",
    "history": "^5.3.0",
    "http-server": "^14.1.1",
    "jest": "^29.7.0",
    "jest-environment-jsdom-sixteen": "^2.0.0",
    "parcel": "^2.9.3",
    "prettier": "^3.0.3",
    "process": "^0.11.10",
    "prop-types": "^15.8.1",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "react-app-rewired": "^2.2.1",
    "react-hot-loader": "^4.13.1",
    "storybook": "^7.4.1",
    "ts-jest": "^29.1.1",
    "ts-loader": "^9.4.4",
    "webpack": "^5.88.2"
  }
}
