import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';

const Page: React.FC<BasePageProps> = ({navigatePage, otherProps}) => {
  const params = otherProps?.params;

  return (
    <>
      {params?.content ? (
        <div dangerouslySetInnerHTML={{__html: params?.content}} />
      ) : (
        <div>No Content</div>
      )}
    </>
  );
};

export default Page;
