import {getCMSPages, getSortedCMSPages} from './amplify/graphql/queries';

import {
  createCMSPages,
  deleteCMSPages,
  updateCMSPages,
} from './amplify/graphql/mutations';
import CMSPagesCache from '@internal/utils/cache/CMSPagesCache';
import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';

const CMSPagesAPI = new UtilFuncAPIs({
  ref: 'CMSPagesAPI',
  cacheFunc: CMSPagesCache,
  filter: {
    key: 'CMS_Pages',
    sortDirection: 'ASC',
  },
  listFunc: getSortedCMSPages,
  getFunc: getCMSPages,
  route: '/members/cms/pages',
  insertFunc: createCMSPages,
  updateFunc: updateCMSPages,
  deleteFunc: deleteCMSPages,
  backupTable: 'cms_pages',
  deleteMSG: 'Page deleted',
  insertMSG: 'Page saved',
  updateMSG: 'Page updated',
  key: 'CMS_PAGE',
  getName: 'getCMSPages',
});

export default CMSPagesAPI;
