import type FieldValidators from './FieldValidators';

type ValidationResult = string | null;

const ValidateField = (
  {type, required, minLength, customMessages}: FieldValidators,
  value: string,
): ValidationResult[] => {
  const errors: ValidationResult[] = [];

  // Check for required fields
  if (required && !value) {
    errors.push(
      customMessages?.required ??
        `${type.charAt(0).toUpperCase() + type.slice(1)} is required`,
    );
  }
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  switch (type) {
    case 'email':
      if (value && !emailRegex.test(value)) {
        errors.push(customMessages?.email ?? 'Email is not valid');
      }
      break;
    case 'password':
      if (value && minLength && value.length < minLength) {
        errors.push(
          customMessages?.minLength ??
            `Password should be at least ${minLength} characters`,
        );
      }
      break;
    case 'text':
      if (value && minLength && value.length < minLength) {
        errors.push(
          customMessages?.minLength ??
            `Field should be at least ${minLength} characters`,
        );
      }
      break;
  }

  return errors;
};
export default ValidateField;
