import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import {FormGrid} from '@internal/components';
import CMSMenusAPI from '@internal/apis/CMSMenusAPI';
import CMSConfigurationsAPI from '@internal/apis/CMSConfigurationsAPI';

const FormConfiguration: React.FC<BasePageProps> = ({
  navigatePage,
  urlParams,
}) => {
  const [loading, setLoading] = React.useState(true);
  const configurationData = React.useRef({} as any);
  const configurationBase = React.useRef([] as any);
  const formConfig = React.useRef<any>([
    {
      name: 'maintenance',
      label: 'Maintenance Enabled',
      fieldType: 'select',
      isRequired: true,
      options: {
        data: [
          {id: '2', value: 'No'},
          {id: '1', value: 'Yes'},
        ],
        dictionary: {id: 'id', value: 'value'},
      },
      value: '1',
      validations: {
        required: true,
        customMessages: {
          required: "Maintenance can't be blank.",
        },
      },
    },
    {
      name: 'topMenu',
      label: 'Top Menu',
      fieldType: 'select',
      isRequired: true,
      options: {
        data: [],
        dictionary: {id: 'id', value: 'menu'},
      },
      validations: {
        required: true,
        customMessages: {
          required: 'Top Menu is required.',
        },
      },
    },
    {
      name: 'bottomMenu',
      label: 'Bottom Menu',
      fieldType: 'select',
      isRequired: true,
      options: {
        data: [],
        dictionary: {id: 'id', value: 'menu'},
      },
      validations: {
        required: true,
        customMessages: {
          required: 'Bottom Menu is required.',
        },
      },
    },
    {
      name: 'favicon',
      label: 'Fav Icon',
      fieldType: 'file',
      isRequired: true,
      fileType: '.ico',
      validations: {
        required: true,
        customMessages: {
          required: 'Fav Icon is required.',
        },
      },
    },
    {
      name: 'logo',
      label: 'Logo',
      fieldType: 'file',
      isRequired: true,
      fileType: '.svg,.png',
      validations: {
        required: true,
        customMessages: {
          required: 'Fav Icon is required.',
        },
      },
    },
    {
      name: 'googleRecaptchaKey',
      label: 'Google Recaptcha Key',
      fieldType: 'input',
      isRequired: true,
      value: '',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Google Recaptcha Key can't be blank.",
        },
      },
    },
    {
      name: 'copyRights',
      label: 'Copy Rights',
      fieldType: 'input',
      isRequired: true,
      value: 'Startup Muster',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "URL can't be blank.",
        },
      },
    },
    {
      name: 'websiteTitle',
      label: 'Website Title',
      fieldType: 'input',
      isRequired: true,
      value: 'Startup Muster 2023',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Title can't be blank.",
        },
      },
    },
    {
      name: 'metaDescription',
      label: 'Meta Description',
      fieldType: 'textarea',
      isRequired: true,
      value:
        'Startup Muster is the largest survey of the Australian startup ecosystem and enables meaningful and timely connections between startups and the businesses.',
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "Meta Description can't be blank.",
        },
      },
    },
  ]);

  React.useEffect(() => {
    void reloadFunc().then();
  }, []);
  const reloadFunc = async (): Promise<void> => {
    setLoading(true);
    const menus = await CMSMenusAPI.getList(navigatePage, false);
    formConfig.current[1].options.data = menus;
    formConfig.current[2].options.data = menus;

    configurationBase.current = await CMSConfigurationsAPI.getList(false, true);
    configurationBase.current.map((configuration: any) => {
      configurationData.current[configuration.config] = configuration.record;
      return configuration;
    });
    formConfig.current.map((field: any) => {
      field.value = configurationData.current[field.name] ?? field.value;
      return field;
    });
    setLoading(false);
  };

  const submitData = (data: any) => {
    const dataValues: any = Object.entries(data);
    dataValues.forEach(async ([key, value]: any, index: number) => {
      const input = {
        config: key,
        record: value,
      };
      if (configurationData.current?.[key]) {
        await CMSConfigurationsAPI.update(
          navigatePage,
          configurationBase.current.find((configuration: any) => {
            return configuration.config === key;
          }),
          input,
        );
      } else {
        await CMSConfigurationsAPI.save(navigatePage, input);
      }
      if (index + 1 === dataValues.length) {
        setLoading(false);
      }
    });
  };

  const loadFavIcon = (data: any, logo: any, isLoad: boolean) => {
    if (!isLoad) {
      data.logo = JSON.stringify(data.logo);
    } else {
      data.logo = JSON.stringify({name: data.logo.name, file: logo.result});
    }
    if (data.favicon.file) {
      data.favicon = JSON.stringify(data.favicon);
      submitData(data);
      return;
    }

    const favicon = new FileReader();
    favicon.readAsDataURL(data.favicon);
    favicon.onload = async () => {
      data.favicon = JSON.stringify({
        name: data.favicon.name,
        file: favicon.result,
      });
      submitData(data);
    };
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    if (data.logo.file) {
      loadFavIcon(data, null, false);
      return;
    }
    const logo = new FileReader();
    logo.readAsDataURL(data.logo);
    logo.onload = () => {
      loadFavIcon(data, logo, true);
    };
  };

  return (
    <React.Fragment>
      <FormGrid
        fields={formConfig.current ?? []}
        onSubmit={handleFormSubmit}
        submitButtonText={'Save'}
        doShowCardHeader
        reloadFunc={reloadFunc}
        title={'Configuration'}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default FormConfiguration;
