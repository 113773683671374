import React from 'react';
import type BasePageProps from '@internal/pages/BasePageProps';
import {FormGrid} from '@internal/components';
import SurveyReportsAPI from '@internal/apis/SurveyReportsAPI';
import * as uuid from 'uuid';
import {toast} from 'react-toastify';
import {Storage} from 'aws-amplify';

const FormReport: React.FC<BasePageProps> = ({navigatePage, urlParams}) => {
  const [loading, setLoading] = React.useState(false);
  const [key, setKey] = React.useState(Date.now());
  const formConfig = React.useRef<any>([
    {
      name: 'year',
      label: 'Report Year',
      fieldType: 'input',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "year can't be blank.",
        },
      },
    },{
      name: 'reportStatus',
      label: 'Status',
      fieldType: 'select',
      isRequired: true,
      options: {
        data: [
          {id: 'Draft', value: 'Draft'},
          {id: 'Coming Soon', value: 'Coming Soon'},
          {id: 'Active', value: 'Active'},
        ],
        dictionary: {id: 'id', value: 'value'},
      },
      value: '1',
      validations: {
        required: true,
      },
    },
    {
      name: 'description',
      label: 'Report information',
      fieldType: 'editor',
      isRequired: true,
      validations: {
        type: 'input',
        required: true,
        customMessages: {
          required: "information can't be blank.",
        },
      },
    },
    {
      name: 'reportImage',
      label: 'Report Image',
      fieldType: 'file',
      isRequired: true,
      fileType: '.jpg,.png,.svg',
      validations: {
        required: true,
        customMessages: {
          required: 'Image is required.',
        },
      },
    },
    {
      name: 'report',
      label: 'Report',
      fieldType: 'file',
      isRequired: true,
      fileType: '.pdf',
      validations: {
        required: true,
        customMessages: {
          required: 'Report is required.',
        },
      },
    },
  ]);
  const record = React.useRef<any>();

  React.useEffect(() => {
    if (urlParams.id) {
      void reloadFunc().then();
    }
  }, []);
  const reloadFunc = async (): Promise<void> => {
    setLoading(true);
    record.current = await SurveyReportsAPI.get(
      navigatePage,
      urlParams.id ?? '',
    );
    for (let i = 0; i < formConfig.current.length; i++) {
      formConfig.current[i].value = record.current[formConfig.current[i].name];
    }
    formConfig.current[formConfig.current.length - 1].value = JSON.stringify({
      name: record.current.s3Name,
      file: 'file',
    });
    setLoading(false);
    setKey(Date.now());
  };

  const uploadToS3 = (data: any, report: any) => {
    report.onload = async () => {
      try {
        data.s3Name = data.report.name;
        data.s3Type = 'pdf';
        const nameList = data.report.name.split('.');
        const uniqueFileName = `${uuid.v1()}.${nameList[nameList.length - 1]}`;
        const filePath = `uploads/${uniqueFileName}`;
        const results = await Storage.put(filePath, report.result, {
          contentType: 'application/pdf',
        });
        if (!results) {
          return;
        }
        data.s3Key = results.key;
        delete data.report;
        loadImage(data);
      } catch (error) {
        console.error('Error uploading to S3:', error);
        toast.error('There was an error uploading the file. Please try again.');
        return undefined;
      }
    };
  };

  const loadImage = (data: any) => {
    const reportImage = new FileReader();
    reportImage.readAsDataURL(data.reportImage);
    reportImage.onload = async () => {
      data.reportImage = JSON.stringify({
        name: data.reportImage.name,
        file: reportImage.result,
      });
      await saveAllData(data);
    };
  };

  const saveAllData = async (data: any) => {
    if (urlParams.id) {
      await SurveyReportsAPI.update(navigatePage, record.current, data);
    } else {
      await SurveyReportsAPI.save(navigatePage, data);
    }
    setLoading(false);
  };
  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    if (data.report.file) {
      if (data.reportImage.file) {
        await saveAllData(data);
        return;
      }
      loadImage(data);
      return;
    }
    const report = new FileReader();
    report.readAsDataURL(data.report);
    uploadToS3(data, report);
  };
  return (
    <React.Fragment key={key}>
      <FormGrid
        fields={formConfig.current ?? []}
        onSubmit={handleFormSubmit}
        submitButtonText={urlParams.id ? 'Update' : 'Save'}
        doShowCardHeader
        reloadFunc={urlParams.id ? reloadFunc : undefined}
        title={urlParams.id ? 'Update Survey' : 'New Survey'}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default FormReport;
