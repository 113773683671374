import React, {useState} from 'react';
import {
  Typography,
  IconButton,
  Menu,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  HeaderLink,
  LogoImageStyled,
  AppBarStyle,
  ToolbarStyle,
  MenuLink,
  HeaderBurger,
} from '@internal/styles';
import MenuItem from '@mui/material/MenuItem';
import type UIGlobalAppBarProps from './UIGlobalAppBarProps';

const UIGlobalAppBar: React.FC<UIGlobalAppBarProps> = ({
  logo,
  menuItems,
  navigate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isMobile && (
        <HeaderBurger>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {menuItems.map((items: any, index: number) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (items.func) {
                    items.func(items.link);
                  } else {
                    location.href = items.link;
                  }
                }}>
                {items.text}
              </MenuItem>
            ))}
          </Menu>

          <Typography variant="h6" style={{flexGrow: 1}}>
            <HeaderLink href="https://startupmuster.com">
              <LogoImageStyled src={logo} />
            </HeaderLink>
          </Typography>
        </HeaderBurger>
      )}
      {!isMobile && (
        <AppBarStyle position="static" color="default">
          <ToolbarStyle>
            <Typography variant="h6" style={{flexGrow: 1}}>
              <HeaderLink
                href="https://startupmuster.com">
                <LogoImageStyled src={logo} />
              </HeaderLink>
            </Typography>
            <div>
              {menuItems.map((items: any, index: number) => (
                <MenuLink
                  variant="body1"
                  component={'span' as any}
                  style={{marginRight: 20}}
                  key={index}
                  onClick={() => {
                    if (items.func) {
                      items.func(items.link);
                    } else {
                      location.href = items.link;
                    }
                  }}>
                  {items.text}
                </MenuLink>
              ))}
            </div>
          </ToolbarStyle>
        </AppBarStyle>
      )}
    </>
  );
};

export default UIGlobalAppBar;
