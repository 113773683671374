import {
  getCMSHeadScripts,
  getSortedCMSHeadScripts,
} from './amplify/graphql/queries';

import {
  createCMSHeadScripts,
  deleteCMSHeadScripts,
  updateCMSHeadScripts,
} from './amplify/graphql/mutations';
import UtilFuncAPIs from '@internal/apis/UtilFuncAPIs';
import CMSHeadScriptsCache from '@internal/utils/cache/CMSHeadScriptsCache';

const CMSHeadScriptsAPI = new UtilFuncAPIs({
  ref: 'CMSHeadScriptsAPI',
  cacheFunc: CMSHeadScriptsCache,
  filter: {
    key: 'CMS_Head_Scripts',
    sortDirection: 'ASC',
  },
  listFunc: getSortedCMSHeadScripts,
  getFunc: getCMSHeadScripts,
  route: '/members/cms/scripts',
  insertFunc: createCMSHeadScripts,
  updateFunc: updateCMSHeadScripts,
  deleteFunc: deleteCMSHeadScripts,
  backupTable: 'cms_head_scripts',
  deleteMSG: 'Script deleted',
  insertMSG: 'Script saved',
  updateMSG: 'Script updated',
  key: 'CMS_Head_Scripts',
  getName: 'getCMSHeadScripts',
});

export default CMSHeadScriptsAPI;
