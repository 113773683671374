import AppBar from '@mui/material/AppBar';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import {Box, Toolbar} from '@mui/material';

export const StyledParentPrivateBar = styled(Box)`
  position: absolute !important;
  width: 100%;
`;
export const StyledAppBar = styled(AppBar)`
  background-color: transparent !important;
  box-shadow: none !important;
  //border-radius: 2px; // Increased radius
  //z-index: 999;
`;

export const StyledToolbar = styled(Toolbar)`
  background-color: #367fbb !important;
  border-radius: 2px; // Increased radius
  position: absolute !important;
  width: calc(100vw - 23.3rem);
  z-index: 999;
  @media (max-width: 1023px) {
    width: calc(100vw - 7rem);
  }
`;

export const StyledDrawer = styled(Drawer)`
  width: 260px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
  border-right: 0px solid;
  outline: 0px;

  & .MuiDrawer-paper {
    width: 260px;
    background-color: rgb(255, 255, 255);
    border-right: 0px solid;
    outline: 0px;
  }
`;
