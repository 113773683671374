import React from 'react';
import type RoutesProps from './RoutesProps';
import PrivateRoute from './private-route/PrivateRoute';
import {Auth} from '@aws-amplify/auth';
import {useNavigate, useParams} from 'react-router-dom';
import {
  FullScreenContainer,
  FullScreenSkeleton,
  CenteredLinearProgress,
  GlobalStylesAdmin,
  GlobalStylesUI,
  PageTitleTypography,
  PageHeaderTitleBox,
  PageContents,
} from '@internal/styles';
import skyline from '@internal/assets/skyline.png';
const Routes: React.FC<RoutesProps> = ({
  title,
  breadcrumb,
  Component,
  type,
  path,
  system,
  ...props
}) => {
  const navigate = useNavigate();
  const [pageLoad, setPageLoad] = React.useState(false);
  const CheckUserBasedType = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      if (type === 'protected') {
        navigate('/');
      }
    } catch (ex: any) {
      if (type === 'private') {
        navigate('/login');
      }
    }
    setPageLoad(true);
  };
  const TypedComponent = (): React.ReactNode => {
    if (type === 'private') {
      return (
        <PrivateRoute
          Component={Component}
          type={type}
          navigate={navigate}
          path={path}
          title={title}
          breadcrumb={breadcrumb}
          urlParams={useParams()}
        />
      );
    }
    return (
      <Component
        type={type}
        navigatePage={navigate}
        path={path}
        urlParams={useParams()}
        otherProps={props}
      />
    );
  };

  React.useEffect(() => {
    setPageLoad(false);
    if (type === 'public') {
      setPageLoad(true);
      return;
    }
    void CheckUserBasedType().then(r => {});
  }, []);

  const params: any = props;
  console.log(params)
  return (
    <>
      {system === 'admin' ? <GlobalStylesAdmin /> : <GlobalStylesUI />}
      {!pageLoad ? (
        <FullScreenContainer>
          <FullScreenSkeleton variant="rectangular" />
          <CenteredLinearProgress color="success" />
        </FullScreenContainer>
      ) : (
        <>
          {system === 'admin' ? (
            <TypedComponent />
          ) : (
            <>
              <PageHeaderTitleBox bgimage={skyline}>
                <PageTitleTypography variant="h1">
                  {params.params.pageTitle}
                </PageTitleTypography>
              </PageHeaderTitleBox>
              <PageContents>
                <TypedComponent />
              </PageContents>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Routes;
