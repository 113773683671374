import styled from 'styled-components';
import {Skeleton, LinearProgress} from '@mui/material';

const FullScreenContainer = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 9999, // or any value to ensure it's on top of other content
  display: 'flex',
  alignItems: 'center', // vertically centers children
  justifyContent: 'center', // horizontally centers children
  backgroundColor: 'rgba(0, 0, 0, 0.1)', // optional, if you want a slight overlay effect
});

const FullScreenSkeleton = styled(Skeleton)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '99vw',
  height: '99vh !important',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999, // or any value to ensure it's on top of other content
});

const CenteredLinearProgress = styled(LinearProgress)({
  width: '60%', // adjust based on your requirements
  zIndex: 1, // to keep it above the skeleton
});
export {FullScreenContainer, FullScreenSkeleton, CenteredLinearProgress};
