import ReportsDownloaders from "./ReportsDownloaders";


const RecordReportsDownloadersRoutes = {
    recordListReportsDownloaders: {
        Component: ReportsDownloaders,
        type: 'private',
        route: '/members/records/reports-downloaders',
        title: 'Reports Downloaders',
        breadcrumb: [{title: 'Home', route: '/'}],
    },
};
export default RecordReportsDownloadersRoutes;
